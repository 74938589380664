.select-date {
  display: block;
  margin-top: 10px;
  float: right;
}

.content-revenues span.clinic,
.panel .debt .panel-body .payments-debit-description {
  display: inline-block;
  width: 80%;
}

.content-revenues span.value,
.panel .debt .panel-body .payments-debit-value {
  display: inline-block;
  width: 19%;
}

.content-revenues,
.panel.debt {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 16px;
  border-top: dashed 1px #e2e2e2;
  margin-top: 25px;
  margin-bottom: 29px;
  padding-top: 17px;
}

.list-group,
.panel-body {
  color: #42526e;
}

.clinic,
.container.dashboard .panel-body span {
  padding-top: 20px;
  padding-bottom: 20px;
}

.clinic.user {
  padding-left: 10px;
}

.resume {
  color: #42526e;
  font-weight: 600;
}

.content-revenues span i {
  margin-right: 7px;
  font-size: 20px !important;
  color: #42526e;
}

.clinic.user img {
  width: 20px;
  color: #bfc0c1;
  margin-right: 7px;
  margin-top: -3px;
}

.content-revenues.footer {
  margin-left: -15px;
  margin-right: -15px;
  background: #f7f7f7;
  margin-bottom: -10px;
  padding: 10px;
  text-align: right;
  font-size: 13px;
  border-top: solid 1px #e6e0e0;
}

.debt {
  margin-top: 30px;
}

.panel .debt {
  border-top: none;
}

.dashboard span a {
  text-decoration: none !important;
  color: #41526c;
  border-bottom: dashed 1px #c2c7ce;
}

.dashboard #dentist {
  float: right;
  margin-bottom: 25px;
  font-size: 14px;
  background: white;
}

.birthday-list-today {
  font-weight: bold;
}

.birthday-list-name {
  width: 60%;
  display: inline-flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.birthday-list-date {
  width: 33%;
  display: inline-block;
  text-align: right;
}

.container.dashboard .panel-body.birthday-list span {
  padding-top: 10px;
  padding-bottom: 10px;
}

.patient-right-side-content-box {
  font-family: Roboto, Helvetica, sans-serif;
}

.patient-right-side-content-box .panel-footer {
  text-align: right;
}

.dashboard h3 {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
}

.line-return-list {
  height: 50px;
}

.birthday-list-name input {
  line-height: 30px;
  margin-right: 10px;
}

.info-box {
  background: #f5f4f4;
  padding: 10px;
  border-radius: 9px;
}

.info-box i {
  margin-right: 5px;
}

.birthday-list-name span,
.birthday-list-name.tooltipster span {
  line-height: 2px;
  cursor: pointer;
  border-bottom: dashed 1px #56657e;
  padding-bottom: 7px !important;
}

.birthday-list-name .tooltipster.strike {
  text-decoration: line-through;
}

.birthday-list-date.strike {
  text-decoration: line-through;
}

.modal-patient-content {
  padding: 7px;
  border-bottom: solid 1px #e6e1e1;
}

.modal-patient-content:last-of-type {
  border-bottom: none;
}

.modal-patient-content span a {
  border: none;
}

.birthday-list-name span.fa.fa-check {
  color: #4f8cdd;
  margin-right: 7px;
  border-bottom: none;
}

.birthday-list-name a {
  border-bottom: none;
}

.no-info {
  text-align: center;
  padding: 15px;
}

.help-dashboard.fa.fa-question-circle {
  float: right;
  margin-top: -10px;
  margin-right: -5px;
  color: rgba(66, 83, 113, 0.6196078431);
}

.footer-panel-dashboard {
  background: #f5f4f4;
  display: block;
  padding: 10px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -16px;
}

.footer-panel-dashboard-receipt {
  background: #f5f4f4;
  display: block;
  padding: 10px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -16px;
  padding-left: 23px;
  margin-top: 20px;
}

.receipt-date {
  max-width: 80px;
  color: grey;
}

.receipt-name {
  width: 200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 11px;
}

.receipt-value {
  text-align: right;
}

.content-receipt {
  margin-top: 30px;
  padding-top: 25px;
  border-top: dashed 1px #e2e2e2;
}

.row.receipt {
  margin-left: 10px;
  height: 25px;
}

.footer-panel-dashboard-receipt a {
  text-decoration: none;
}

.payments-debit-description {
  float: left;
}

.payments-debit-value {
  float: right;
}

.value.billed {
  text-align: right;
}

.col-md-7.next-calls .table.table-bordered {
  font-size: 12px;
}

.col-md-7.next-calls .table.table-bordered thead tr {
  background: #fafafa;
  color: #666666;
  font-weight: 600;
}

.dashboard .table.table-bordered tbody tr {
  height: 50px;
}

.sidebar-left-title {
  width: 45%;
}

.sidebar-center-content {
  width: 26%;
}

.sidebar-right-content {
  width: 26%;
  text-align: right;
}

.sidebar-left-title,
.sidebar-center-content,
.sidebar-right-content {
  display: inline-block;
}

.sidebar-center-content {
  text-align: center;
}

.sidebar-right-content {
  text-align: right;
}

.panel-body.birthday-list hr {
  margin: 18px 0;
}

.dashboard .table.table-bordered thead tr {
  background-color: #fafafa;
  font-weight: 600;
}

.dashboard .table.table-bordered a {
  text-decoration: none;
}

.dashboard .table.table-bordered small {
  color: #a5a0a0;
}

.dashboard .empty-schedule {
  height: 130px;
  text-align: center;
}

.fc-header-toolbar .fc-right {
  margin-top: -6px;
  margin-right: 60px;
}

.fc-right .fc-state-active {
  font-weight: bold;
  background-color: #FFFFFF;
}

.fc-right button {
  box-shadow: inset 0 0px 0px;
}

.fc-right button:focus {
  font-weight: bold;
  background-color: #FFFFFF;
}

.fc-right button:hover {
  font-weight: bold;
  background-color: #FFFFFF;
}

.fc-button.fc-state-default.fc-corner-left.fc-corner-right.fc-state-active {
  font-weight: 600;
  color: #076bf7;
  cursor: default;
}

.fc-button.fc-state-default.fc-corner-left.fc-corner-right.fc-state-default {
  margin-right: 5px;
}

.fc-button.fc-state-default.fc-corner-left.fc-corner-right.fc-state-hover {
  background: #f8f8f8;
  font-weight: 400;
  border-radius: 5px;
}

.fc-prev-button.fc-button.fc-state-default.fc-state-hover {
  background-color: #ffffff;
}

.fc-next-button.fc-button.fc-state-hover {
  background-color: #fff;
}

.fc-prev-button.fc-button.fc-state-default.fc-state-hover span, .fc-next-button.fc-button.fc-state-default.fc-state-hover span {
  background: #e0e0e0;
  font-weight: 400;
  border-radius: 26px;
}

.fc-prev-button.fc-button.fc-state-default {
  background-color: #ffffff;
}
