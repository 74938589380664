@import "components/debt-pay.css";

.debts-date {
  color: gray;
  font-size: 11px;
  margin-right: 10px;
}

.debts-tooth-faces {
  color: gray;
  font-size: 11px;
}

.number-with-delimiter {
  color: #adadad;
  margin-left: 10px;
}

.content-pay-modal {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 600;
  color: #868585;
  display: block;
  border-bottom: dashed 1px #dad8d8;
}

.form-control.expires_at.hasDatepicker {
  height: 30px;
  margin-top: 7px;
  font-size: 12px;
  color: #868585;
}

.col-md-12.amount-box {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.col-md-12.amount-box small {
  color: #a9a6a6;
}

.col-md-12.amount-box span {
  font-weight: 600;
}

.table.debt-list input[type=checkbox] {
  transform: scale(1.2);
}

.table.debt-list .dropdown-menu {
  left: -120px !important;
}

.table.debt-list .btn.btn-dropdown.dropdown-toggle {
  font-weight: 300 !important;
}

.table.debt-list .disabled {
  pointer-events: none;
  cursor: default;
}

.debt-summary__total_card {
  height: 124px;
}

#batch-payments-container input {
  color: #ffffff;
}

#batch-payment-modal-label {
  font-size: 13px;
  color: #3182ce;
  font-weight: 600;
}

#batch-payment-destroy {
  border: solid 1px;
  color: #de8888;
}

#boleto-print {
  border: solid 1px;
  color: #70ca63;
}

.debt-list-container {
  max-height: 195px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  background-color: #F9FAFB;
  padding: 10px;
  border: solid 0.5px;
  border-color: #F3F4F6;
}
@media screen and (max-width: 768px) {
  .debt-list-container {
    max-height: unset;
    overflow-y: auto;
  }
}

#boleto-button-disabled {
  font-weight: 600;
  padding-top: 9px;
  padding-bottom: 13px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 0.25rem;
  cursor: default;
  color: #ffffff;
  background-color: #70ca63;
  opacity: 0.75;
}

#procedures-list-container {
  max-height: 195px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F9FAFB;
  border-radius: 5px;
  border: solid 0.5px;
  border-color: #F3F4F6;
}
@media screen and (max-width: 768px) {
  #procedures-list-container {
    max-height: unset;
    overflow-y: auto;
  }
}

#procedures-list-container td {
  border-top: 0;
}

#boleto-topbar-button-container {
  width: auto;
}

#boleto-topbar-container {
  display: flex;
  justify-content: center;
}

#debts-selected-container {
  width: auto;
}

#batch-receipt-print {
  border: solid 1px;
  color: #70ca63;
}

#batch-payment-button-disabled {
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 0.25rem;
  cursor: default;
  color: #ffffff;
  background-color: #70ca63;
  opacity: 0.55;
}

#boleto-button-disabled {
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 0.25rem;
  border: solid 1px;
  cursor: default;
  color: #70ca63;
  background-color: #ffffff;
  opacity: 0.55;
}

#batch-payment-destroy-button-disabled {
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 0.25rem;
  border: solid 1px;
  cursor: default;
  color: #DE8888;
  background-color: #ffffff;
  opacity: 0.55;
}

#batch-receipt-button-disabled {
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 0.25rem;
  border: solid 1px;
  cursor: default;
  color: #70ca63;
  background-color: #ffffff;
  opacity: 0.55;
}

.debts-summary__installments-button, .debts-summary__installments-button:hover {
  border: solid 1px;
  color: #70ca63 !important;
}

.admin-form--normalize-inputs .lbl-text {
  font-size: 13px !important;
  line-height: 1.5;
  margin-bottom: 5px !important;
}

.admin-form--normalize-inputs .select > select {
  height: 39px;
}

.admin-form--normalize-inputs .select {
  height: auto;
}

.debts-list__name-container {
  width: 40vw;
  width: var(--debt-name-width);
}

.debt-list__name-status {
  width: 100px;
}
