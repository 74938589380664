.finalized-procedure {
  background-color: #e1ffe5;
}

.procedure-finalized-mark {
  padding: 3px;
  line-height: -26px;
  border: dashed 1px #727a73;
  font-size: 14px !important;
}
