.photo-crop-upload-loading {
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
}

.photo-crop-upload-preview-image {
  border-radius: 99999px;
  width: 85px;
  height: 85px;
  margin: 0 auto;
}
