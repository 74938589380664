@import "fullcalendar/dist/fullcalendar";
@import "jquery-datetimepicker/build/jquery.datetimepicker.min";
@import "jquery-nice-select/css/nice-select";
@import "bootstrap-select/dist/css/bootstrap-select";
@import "tooltipster/dist/css/tooltipster.bundle.min";
@import "tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-light.min";
@import "tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-shadow.min";
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "daterangepicker/daterangepicker";
@import "chartist/dist/chartist.min";
@import "chartist-plugin-tooltips/dist/chartist-plugin-tooltip";
@import "@uppy/core/dist/style.min";
@import "@uppy/dashboard/dist/style.min";
@import "@uppy/webcam/dist/style.css";
@import "tui-image-editor/dist/tui-image-editor.min";
@import "tui-color-picker/dist/tui-color-picker.min";

@import "./theme.css";
@import "./admin-forms.css";
@import "./patients.css";
@import "./plans.css";
@import "./messages.css";
@import "./dashboards.css";
@import "./financial.css";
@import "./odontogram.css";
@import "./uploads.css";
@import "./quotes.css";
@import "./documents.css";
@import "./registrations_one_step.css";
@import "./registrations_multiple_steps.css";
@import "./professionals.css";
@import "./debts.css";
@import "./expense-categories.css";
@import "./events.css";
@import "./anamneses.css";
@import "./photos.css";
@import "./products.css";
@import "./components/navbar.css";
@import "./mobile.css";
@import "./components/datepicker.css";
@import "./components/nice-select.css";
@import "./components/professional-switcher.css";
@import "./components/progress-bar.css";
@import "./components/black-friday.css";
@import "./components/tooltip.css";
@import "./evolutions.css";
@import "./insights.css";
@import "./procedures.css";
@import "./actiontext.css";
@import "./my-website.css";
@import "./payment_fees.css";


html {
    font-size: 16px;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    cursor: pointer;
}

[v-cloak] {
    display: none;
}

#toast-container {
    -webkit-animation: scale-fade-in 0.2s linear;
    -moz-animation: scale-fade-in 0.2s linear;
    animation: scale-fade-in 0.2s linear;
    position: fixed;
    top: 40px;
    text-align: center;
    z-index: 99999999;
    left: 50%;
    transform: translateX(-50%);
}

.toast {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

#impersonating {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 9999999;
    padding: 10px;
    background: red;
    color: white;
    a {
        color: white;
        text-decoration: underline;
    }
}

.dropdown-menu {
    min-width: 205px;
}

 /* remove the up/down arrows in number input fields */
 /* have a problem with events not being triggered when using them */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.reveal-validation:invalid {
    border: 2px solid #ee7f6d !important;
}

.patient-form .field_with_errors label,
.field_with_errors label {
    color: #e9573f;
}

.admin-form.theme-primary .field_with_errors .gui-input,
.field_with_errors .form-control,
.field_with_errors .gui-textarea {
    border-color: #ee7f6d !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.field_with_errors .message {
    display: block !important;
    margin-top: 6px;
    padding: 0 3px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    line-height: normal;
    font-size: 0.85em;
    color: #de888a;
}

.flash-container {
    margin-top: 60px;
}

.alert {
    margin-bottom: 0;
    margin-top: -1px;
}

#content {
    padding-top: 20px;
}

#spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #c3c3c3;
    border-top: 4px solid white;
    border-radius: 50%;
}

#spinner {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1.2s;
    -webkit-animation-name: rotate;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-transition-property: -moz-transform;
    -moz-animation-name: rotate;
    -moz-animation-duration: 1.2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.trial-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 0;
    padding-top: 6px;
    /* padding-bottom: 0.9rem; */
    text-align: center;
    background: #725ead;
    color: #fff;
    font-size: 1rem;
    height: 35px;
    a {
        color: #fff;
        font-weight: bold;
    }
}

.trial-banner-boleto {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 0;
    padding-top: 6px;
    /* padding-bottom: 0.9rem; */
    text-align: center;
    background: #725ead;
    color: #fff;
    font-size: 1rem;
    height: 35px;
    a {
        color: #fff;
    }
}

.trial-banner.danger {
    background-color: #e9573f;
}

.trial-banner-danger-boleto {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 0;
    padding-top: 6px;
    /* padding-bottom: 0.9rem; */
    text-align: center;
    background: #e9573f;
    color: #fff;
    font-size: 1rem;
    height: 35px;
    a {
        color: #fff;
    }
}

.fc-widget-header-fixed {
    position: fixed;
    top: 60px;
    z-index: 1;
    background: white;
    border-bottom: 1px solid #ddd !important;
}

.fc-widget-header-fixed * {
    box-sizing: content-box;
}

.fc .fc-row .fc-content-skeleton table, .fc .fc-row .fc-content-skeleton td, .fc .fc-row .fc-helper-skeleton td {
  background: white;
  border-color: #d6d6d6;
}

.fc-day-grid .fc-event {
  border: 1px solid #3b82f6;
  border-left: 4px solid #3b82f6;
  background: #dbeafe;
  margin-top: 0;
  margin: 3px;
}

tr:first-child > td > .fc-day-grid-event {
  margin-top: 0;
}

.fc-body > tr > .fc-widget-content {
  border-top: 0 hidden transparent;
}

.fc-day-grid .fc-event .fc-content {
  margin: 2px 0px;
}

.fc-day-grid .fc-event .fc-content .fc-title {
  color : #3b82f6;
  font-size: 0.9em;
}

.fc-day-grid-event {
  cursor: default;
}

.fc-divider.fc-widget-header {
  padding: 0px;
}

.fc-divider {
  border-bottom: 0;
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: initial;
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 0px;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
    border-color: #d6d6d6;
}

.fc-nonbusiness {
    background: #b7b7b7;
}

.fc-widget-content {
    cursor: cell;
}

.fc-time-grid-container {
  cursor: cell;

}

.current-time {
    background: linear-gradient( to bottom, rgb(221, 235, 255) 0%, rgba(221, 235, 255, 0) 100%) !important;
    padding-left: 5px;
    color: #4a4949;
}

.swal2-popup .swal2-title {
    font-size: 1.5em !important;
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}

.ui-datepicker-inline .ui-datepicker-header {
    background: none;
    border-bottom: none;
}

.ui-datepicker-inline .ui-datepicker-prev,
.ui-datepicker-inline .ui-datepicker-next {
    font-size: 23px;
}

.ui-datepicker-inline .ui-datepicker-title {
    font-size: 13px;
}

.ui-datepicker td a {
    color: #3a3a3a;
}

.ui-datepicker td.ui-datepicker-other-month a {
    color: #aaa;
}

.ui-datepicker td a {
    border-radius: 2px;
}

.ui-datepicker-inline .ui-datepicker-today a {
    background-color: #428bca;
}

.ui-datepicker td {
    border: 0;
    padding: 1px 4px;
}

.ui-datepicker {
    font-size: 11px;
    padding: 0px;
}

.fc-toolbar.fc-header-toolbar {
    text-transform: capitalize;
    height: 20px;
}

.fc-view-container .fc-event {
  padding: 1px 2px;
  border-radius: 5px;
}

.fc-event {
    margin: 0;
}

.fc-view-container .fc-event-compromise {
    border-left: 1px solid #ddd;
    background: repeating-linear-gradient( 45deg, #f5f6f5, #f5f6f5 5px, #ffffff 5px, #ffffff 10px);
    color: #515151;
}

.fc-event.appointment-status-0 {
  border-left: 4px solid #64c8f4;
  background: rgba(191,236,255, 89%);
}

.fc-event.appointment-status-1 {
  border-left: 4px solid #70ca63;
  background-color: rgba(209,255,191,94%);
}

.fc-event.appointment-status-2 {
  border-left: 4px solid #a0a0a0;
  background: #efefeff5;
}

.fc-event.appointment-status-3,
.fc-event.appointment-status-4,
.fc-event.appointment-status-5 {
    border-left: 4px solid #f57e69;
    background-color: rgb(255, 201, 211);
}

.fc-event.appointment-status-6 {
    border-left: 4px solid #6b21a8;
    background-color: #d8b4fe;
}

.fc-event.appointment-status-7 {
    border-left: 4px solid #f8c969;
    background-color: #f3dda9;
}

.fc-title {
  font-weight: 600;
}

.appointment-status-0 .fc-title,
.appointment-status-0 .fc-time {
  color: #026693;
}

.appointment-status-1 .fc-title,
.appointment-status-1 .fc-time {
  color: #117003;
}

.appointment-status-2 .fc-title,
.appointment-status-2 .fc-time {
  color: #626262;
}

.appointment-status-3 .fc-title,
.appointment-status-4 .fc-title,
.appointment-status-5 .fc-title,
.appointment-status-3 .fc-time,
.appointment-status-4 .fc-time,
.appointment-status-5 .fc-time{
  color: #af394f;
}

.appointment-status-6 .fc-title,
.appointment-status-6 .fc-time {
  color: #6b21a8;
}

.appointment-status-7 .fc-title,
.appointment-status-7 .fc-time {
  color: #815a0c;
}


.fc-view-container .fc-event .fc-content {
    line-height: 1.1;
    text-overflow: unset;
}

.fc-time-grid-event .fc-time {
    font-size: 0.8em;
}

ul.ui-autocomplete {
    z-index: 2000;
    position: absolute;
    list-style: none;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 8px;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    .ui-menu-item-wrapper {
        padding-left: 12px;
        padding-right: 12px;
    }
    .ui-state-active {
        cursor: pointer;
        color: #ffffff;
        background-color: #4a89dc;
    }
}

.typeahead,
.tt-query,
.tt-hint {}

.typeahead {
    background-color: #fff;
}

.typeahead:focus {
    border: 2px solid #4a89dc;
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999;
}

.tt-menu {
    width: 100%;
    max-height: 260px;
    margin: 1px 0;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

.tt-suggestion {
    padding: 6px 20px;
    font-size: 12px;
    font-weight: 500;
    color: #6b6b6b;
}

.tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #4a89dc;
}

.tt-suggestion.tt-cursor {
    color: #ffffff;
    background-color: #4a89dc;
}

.tt-suggestion p {
    margin: 0;
}

.typeahead-patient-photo {
    width: 35px;
    height: 35px;
    float: left;
    border-radius: 9999px;
    margin-top: 2px;
}

.tooltip-patient-photo {
  width: 55px;
  height: 55px;
  float: left;
  border-radius: 9999px;
  margin-left: -10px;
}

.tooltip-patient-initial {
  color: #92b7ea;
  background-color: #f7f7f8;
  border-radius: 9999px;
  width: 50px;
  height: 50px;
  float: left;
  display: flex;
  justify-content: center;
  font-size: 25px;
  align-items: center;
  margin-top: 2px;
  margin-left: -10px;
}

.typeahead-patient-initial {
    color: #92b7ea;
    background-color: #f7f7f8;
    border-radius: 9999px;
    width: 35px;
    height: 35px;
    float: left;
    display: flex;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    margin-top: 2px;
}

.typeahead-text {
    float: left;
    margin-left: 15px;
    width: 86%;
}

.typeahead-name {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

.typeahead-meta {
    font-size: 0.9em;
}

.typeahead-not-found {
    padding: 3px 20px;
}

.fc-prev-button.fc-button.fc-state-default.fc-corner-left,
.fc-today-button.fc-button.fc-state-default.fc-state-disabled,
.fc-next-button.fc-button.fc-state-default.fc-corner-right,
.fc-today-button.fc-button.fc-state-default {
    background: #ffffff;
}

.fc-today-button.fc-button.fc-state-default.fc-state-disabled,
.fc-today-button.fc-button.fc-state-default {
  border: solid 1px #cacaca;
  z-index: 99;
  margin-top: -1px;
  padding-top: 5px;
  padding-bottom: 6px;
  border-radius: 3px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  margin-right: 8px;
}

.fc-today-button.fc-button.fc-state-default.fc-state-disabled:hover,
.fc-today-button.fc-button.fc-state-default:hover {
  background-color: #f8f8f8;
}

#mobile-modal .modal-backdrop.in {
    opacity: 0.9;
    background-color: #ffffff;
}

.swal-button {
    border-radius: 0px;
}

.swal-button.swal-button--confirm {
    background-color: #2875d0;
    border-radius: 0px;
}

.title-tooltip {
    background-color: #f4f4f4;
    border-bottom: solid 1px #dddddd;
    margin: -6px -14px;
    padding: 10px;
    padding-left: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: gray;
    font-weight: 600;
}

.title-tooltip a {
    text-decoration: none;
}

::-webkit-input-placeholder {
    color: #a0a0a0 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #a0a0a0 !important;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #a0a0a0 !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #a0a0a0 !important;
}

.radio-list .bs-component {
    position: relative;
    float: left;
    margin-right: 25px;
    margin-top: 12px;
}

.radio-list.patient .bs-component {
    position: relative;
    float: left;
    margin-right: 20px;
    margin-top: 12px;
}

.bs-component .radio-custom label {
    padding-left: 24px !important;
}

.bs-component.gender-patient {
    margin-right: 7px !important;
}

.bs-component.gender-patient-femele {
    margin-right: 0px !important;
}

.bs-component.gender-patient-femele label {
    padding-left: 23px !important;
}

.bs-component.gender-patient label {
    padding-left: 23px !important;
}

.radio-custom label:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 64px;
    width: 20px;
    height: 20px;
    border: 1px solid #9a9a9a;
}

.btn-cancel-form {
    float: left;
}

.lead.empty {
    display: block;
    margin-top: 30px;
    text-align: center;
}

.btn-back-default {
    display: inline-block;
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: 550;
    font-size: 13px;
    text-decoration: none !important;
}

.btn-back-default span {
    margin-right: 5px;
}

.fc-center h2 {
    font-size: 20px;
    margin-top: -2px;
}

.fc-button-group {
    margin-top: -5px;
}


.fc-day-header.fc-widget-header {
    padding: 11px;
}

.fc-slats tr {
    height: 28px;
}

.fc button:active,
.fc button.active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.saved-prescriptions-list li a {
    display: inline-block;
}

.saved-prescriptions-list>li:hover,
.saved-prescriptions-list>li:focus {
    background-color: #f5f5f5;
}

.dropdown-menu.saved-prescriptions-list>li>a:hover,
.dropdown-menu.saved-prescriptions-list>li>a:focus {
    background-color: initial;
}

.saved-prescriptions-list-delete {
    float: right;
    visibility: hidden;
    color: #a2a2a2 !important;
}

.saved-prescriptions-list>li:hover .saved-prescriptions-list-delete,
.saved-prescriptions-list>li:focus .saved-prescriptions-list-delete {
    visibility: initial;
}

.dropdown-menu>li>.saved-prescriptions-list-delete:hover,
.dropdown-menu>li>.saved-prescriptions-list-delete:focus {
    text-decoration: underline;
}

.modal-patient.modal-body {
    min-height: 370px;
    margin-bottom: -16px;
}

.modal-patient .form-group label {
    padding-bottom: 5px;
    color: #585858;
}

.modal-patient .extra-info-patient label {
    padding-bottom: 5px;
}

.modal-patient .panel {
    margin-right: -15px;
    margin-left: -15px;
    margin-top: -15px;
    margin-bottom: 0px;
}

.modal-patient .modal-body {
    border: none;
}

.modal-patient .panel-tabs.panel-tabs-border>li.active>a {
    border-top: 2px solid #ffffff;
}

.read-only-mode-message {
    background-color: #e9573f;
    color: #fff;
    padding: 5px;
    padding-left: 14px;
    font-weight: 600;
    border-radius: 4px;
}

@media (max-width: 850px) {
    .dropdown-toggle.fw600 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.container.establishment .col-md-4.top-pading.img small {
    display: block;
}

.container.establishment .col-md-4.top-pading.img small a {
    color: grey;
}

.container.establishment .col-md-4.top-pading.img {
    text-align: center;
}

head .container.establishment .establishment label {
    margin-bottom: 5px;
}

.container.establishment input[type="file"] {
    display: none;
}

.container.establishment #file-name {
    display: block;
    margin-left: 10px;
    color: #6f6f6f;
    margin-top: 5px;
    margin-bottom: 5px;
}

.container.establishment .input-wrapper label {
    /* background-color: #bbc3c7; */
    border-radius: 12px;
    color: #fff;
    margin: 10px;
    padding: 6px 20px;
    color: #a2a2a2;
    border: solid 1px #a2a2a2;
}

.container.establishment .input-wrapper label:hover {
    color: #777777;
    cursor: pointer;
}

.container.establishment .message-codes {
    list-style: none;
    color: grey;
    margin-left: 15px;
    margin-top: 20px;
    padding-bottom: 20px;
}

.container.establishment .message-codes .tag {
    margin-right: 20px;
    line-height: 40px;
    width: 275px;
    display: inline-flex;
    font-family: monospace;
    color: #4e4d4d;
}

.container.establishment .message-codes .tag.extrainfo {
    margin-right: 20px;
    line-height: 40px;
    width: 100%;
    display: inline-flex;
    font-family: monospace;
    color: #e23838;
}

.container.establishment .row.message-code-block {
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
}

.container.establishment .title-message-code {
    margin-left: 15px;
    padding-top: 30px;
    display: block;
    font-weight: 600;
    margin-bottom: 0px;
}

.container.establishment .subtitle {
    margin-top: 20px;
    display: block;
    margin-left: 15px;
}

.help-block.tip-add-procedure {
    text-align: right;
    margin-bottom: 0px;
}

.tip-add-procedure-content {
    cursor: pointer;
}

.nav-tabs>li>a {
    border-radius: 4px 4px 0 0;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    border-bottom-color: white;
}

.daterangepicker {
    font-family: inherit;
    margin-top: 3px;
}

.daterangepicker::before {
    border: none;
}

.daterangepicker::before,
.daterangepicker::after {
    content: none;
}

.daterangepicker .ranges ul {
    padding: 7px 0;
    width: 170px;
}

.daterangepicker .ranges li {
    font-size: 13px;
    padding: 8px 12px;
    color: #4a5568;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
    color: #3182ce;
    background: #f7fafc;
    border-color: #f7fafc;
}

.daterangepicker td.start-date,
.daterangepicker td.end-date {
    border-radius: 4px;
}

.daterangepicker td.today {
    border-radius: 4px;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
    border: none;
}

.daterangepicker .drp-calendar td.today,
.daterangepicker .drp-calendar td.today.active {
    background: rgba(74, 137, 220, 0.6);
    color: #fff;
}

.daterangepicker .drp-calendar td.active.start-date {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.daterangepicker .drp-calendar td.active.end-date {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.table-order-indicator {
    display: inline-block;
    width: 0;
    height: 0;
    margin-bottom: 2px;
    margin-left: 2px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.table-order-indicator-asc {
    border-bottom: 5px solid #4a4949;
}

.table-order-indicator-desc {
    border-top: 5px solid #4a4949;
}

.ui-sortable-handle {
    cursor: move;
}

.professional-alert-message {
    padding: 15px 15px 10px;
    font-size: 13px;
    color: #2a4365;
}

.professional-alert-message i {
    margin-right: -5px !important;
    color: #e9573f;
}

.professional-alert-message a {
    color: #2a4365;
    text-decoration: underline !important;
}

.saved-custom-documents-list li a {
    display: inline-block;

    @media screen and(max-width: 769px) {
        white-space: normal;
    }
}

.saved-custom-documents-list>li:hover,
.saved-custom-documents-list>li:focus {
    background-color: #f5f5f5;
}

.dropdown-menu.saved-custom-documents-list>li>a:hover,
.dropdown-menu.saved-custom-documents-list>li>a:focus {
    background-color: initial;
}

.saved-custom-documents-list-delete {
    float: right;
    visibility: hidden;
    color: #a2a2a2 !important;
}

.saved-custom-documents-list>li:hover .saved-custom-documents-list-delete,
.saved-custom-documents-list>li:focus .saved-custom-documents-list-delete {
    visibility: initial;
}

.dropdown-menu>li>.saved-custom-documents-list-delete:hover,
.dropdown-menu>li>.saved-custom-documents-list-delete:focus {
    text-decoration: underline;
}

.fc-content .fc-time {
    display: inherit;
}

.fc-time-grid-event.fc-short .fc-time:before {
    content: attr(data-start);
}

.fc-content__professional-name{
    font-size: 10px;
    color: #2f446bc2
}

.modal-patient-returns {
    background-color: white;
}

.select-return-patient button {
    height: 39px;
    background-color: white;
    border-color: #dddddd;
    border-radius: 0;
}

.select-return-patient .bootstrap-select > .dropdown-toggle {
    width: 123%;
}

.patient-returns .table > tbody + tbody {
    border-top: 1px solid #eeeeee;
}

.patient-returns-options-td {
    white-space: nowrap;
}

.patient-returns {
    overflow-y: auto;
    max-height: 450px;
    min-height: 450px;
}

.return-patients-datepicker {
    height: 65%;
}

.return-patients-filter-button {
    line-height: 13px;
}

.fc-axis.fc-time.fc-widget-content {
  color: #000000;
}

.fc-time-grid-event.fc-short .fc-time:after{
    content: unset
}

.fc-time-grid-event.fc-short .fc-time:before{
    margin-right: 2px;
}

.fc-icon.fc-icon-left-single-arrow, .fc-icon.fc-icon-right-single-arrow  {
  padding: 5px;
  border-radius: 89px;
  height: 25px;
}

.fc-next-button.fc-button.fc-state-default.fc-corner-right.fc-state-hover .fc-icon.fc-icon-right-single-arrow,
.fc-prev-button.fc-button.fc-state-default.fc-corner-left.fc-state-hover .fc-icon.fc-icon-left-single-arrow {
  background-color:#e0e0e0;
}

/* Leandro - Adjusting the red current time indicator */
.fc-now-indicator.fc-now-indicator-line {
  border-color: #ef6c00 !important;
  background-color: #ef6c00;
  height: 2px;
  margin: 0;
  border: none;
}

.fc-now-indicator.fc-now-indicator-line::before {
  background-color: #ef6c00;
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-top: -6px;
  margin-left: -7px;
}

.fc-nonbusiness {
  background: repeating-linear-gradient(305deg, #b7b7b7, #e7e7e7 4px, #b7b7b7 5px, #c1c1c1 5px);
}

.nav.navbar-nav.navbar-left {
  margin-left: 20px;
}

.nav.navbar-nav.navbar-left a {
  padding-left: 0px;
  padding-right: 0px;
  height: 56px;
  padding-bottom: 15px;
}

.nav.navbar-nav.navbar-left a:hover, .nav.navbar-nav.navbar-left a:focus {
    color: #175CD3;
    background-color: #EFF8FF;

}
.nav.navbar-nav.navbar-left a {
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin-top: 12px;
    margin-bottom: 8px;
}
.nav.navbar-nav.navbar-left .active a {
    color: #175CD3;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #EFF8FF;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin-top: 12px;
    margin-bottom: 8px;
}

.navbar-nav.navbar-left li {
  margin-right: 4px;
}

.recommendation-icon {
    z-index: 2147483003;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

recommendation-button:focus {
    outline: none;
    box-shadow: none;
}

@media (max-width: 730px) {
    .recommendation-label {
        display: none;
    }
}

@media (max-width: 950px) {
    .navbar-nav.navbar-left li {
        margin-right: 10px;
    }
}

@media (max-width: 580px) {
    #recommendation-li {
        display: none;
    }
}

.today-button-suggestion-of-times {
    border: solid 1px #cacaca;
    border-radius: 3px;
    color: black;
    font-weight: 600;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 12px;
    padding-left: 12px;
}

.today-button-suggestion-of-times:hover {
    background-color: #f8f8f8;
}

.suggestion-of-times-chevron {
    color: #666666;
}

.suggestion-times-body {
    min-height: 358px;
}

.suggestion-of-time-chevron-button:focus {
    outline: none;
    box-shadow: none;
}

.suggestion-times-morning-body {
    max-height: 108px;
    overflow: auto;
}

.suggestion-times-afternoon-body {
    max-height: 162px;
    overflow: auto;
}

.list-of-times-container {
    min-height: 415px;
}

.list-of-times-spinner {
    margin-top: 140px;
}

.appointment-duration-label {
    white-space: nowrap;
}

.input-icons-appointment{
    position: relative;
}

.input-icons-appointment i {
    position: absolute;
}

.input-icons-appointment {
    width: 100%;
}

.icon-appointment {
    padding: 10px;
    margin-top: 3px;
    z-index: 1;
    right: 10px;
}

.toast-success {
    position: inline-block;
}

.toast-success button, .toast-error button{
    float: right;
}

.toast-close-button {
    font-size: large;
    margin-left: 10px;
}

.toast-message {
    white-space: nowrap;
    margin-right: 30px;
    margin-top: 2px;
}

.tags-container {
    div:first-child:nth-last-child(n + 2), div:nth-child(2):nth-last-child(n + 2){
        margin-right: -4px;
    }
}

.tooltipster-base.tooltipster-sidetip.tooltipster-dark .tooltipster-box {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    color: #fff;
    border-color: rgba(0, 0, 0, 0.9);
}

.tooltipster-sidetip.tooltipster-dark .tooltipster-content {
    color: #fff;
}

.tooltipster-sidetip.tooltipster-dark.tooltipster-top .tooltipster-arrow-background {
    border-top-color: rgba(0, 0, 0, 0.9);
}

.tooltipster-sidetip.tooltipster-light.tooltipster-bottom .tooltipster-arrow-background {
    border-bottom-color: rgba(0, 0, 0, 0.9);
}

select.reveal-validation:invalid + .nice-select{
    border-color: #FDA29B;
    border-width: 2px;
}
