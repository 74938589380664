.container.categories .content-header {
  text-align: left;
}

.container.categories .table-block {
  width: 600px;
  margin: 0 auto;
}

.container.categories .table-block .table.table-striped {
  border: solid 1px #eaeaea;
}

.container.categories .content-header .left-header {
  float: left;
}

.container.categories .content-header .right-header {
  float: right;
}

.container.categories .opt-table {
  width: 50px;
  text-align: right;
}

.container.categories .lbl-text {
  margin-bottom: 20px;
}
