@charset "UTF-8";
@media only screen and (max-width: 769px) {
  .stack-table table, .stack-table thead, .stack-table tbody, .stack-table th, .stack-table td, .stack-table tr {
    display: block;
  }
  .stack-table thead tr {
    height: unset;
    left: -9999px;
    padding: 0;
    position: absolute;
    top: -9999px;
  }
  .stack-table tr {
    border: 1px solid #ccc;
    height: unset !important;
    margin-top: 1rem;
  }
  .stack-table tr:first-child {
    margin-top: 0;
  }
  .stack-table td {
    border: none;
    border-bottom: 1px solid #eee;
    border-top: none !important;
    position: relative;
    margin-top: 1.75rem;
  }
  .stack-table td:last-child {
    border-bottom: none;
  }
  .stack-table td.icon-col {
    margin-top: 0;
  }
  .stack-table--no-gutter td, .stack-table td.stack-table--no-gutter {
    margin-top: 0;
  }
  .stack-table td:before {
    position: absolute;
    top: -1.5rem;
    left: 0;
    width: 100px;
    padding-left: 0.5rem;
    white-space: nowrap;
    margin-left: 0;
  }
  .quotes-list > .stack-table > tbody > tr:nth-child(odd) td:nth-of-type(1):before {
    content: "Data";
  }
  .quotes-list > .stack-table > tbody > tr:nth-child(odd) td:nth-of-type(2):before {
    content: "Descrição";
  }
  .quotes-list > .stack-table > tbody > tr:nth-child(odd) td:nth-of-type(3):before {
    content: "Valor";
  }
  .quotes-list > .stack-table > tbody > tr:nth-child(odd) td:nth-of-type(4):before {
    content: "";
  }
  .stack-table td.stack-table--column-name-attribute:before {
    content: attr(data-column-name);
  }
  .stack-table.procedures-list td:before {
    padding-left: 0.625rem;
    padding-bottom: 0.375rem;
  }
  .stack-table.procedures-list td:nth-of-type(1):before {
    content: "Data";
  }
  .stack-table.procedures-list td:nth-of-type(2):before {
    content: "Procedimento";
  }
  .stack-table.procedures-list td:nth-of-type(3):before {
    content: "Dente";
  }
  .stack-table.procedures-list td:nth-of-type(4):before {
    content: "Faces";
  }
  .stack-table.procedures-list td:nth-of-type(5):before {
    content: "";
  }
  .stack-table.debt-list td {
    width: 100%;
  }
  .stack-table.debt-list td:nth-of-type(1):before {
    text-align: left !important;
  }
  .stack-table.debt-list td:nth-of-type(2) {
    text-align: right !important;
    display: none;
  }
  .stack-table.debt-list td:nth-of-type(3) {
    text-align: right !important;
    display: none;
  }
  .stack-table.debt-list td:nth-of-type(4):before {
    text-align: right !important;
  }
  .evolutions-table .stack-table tbody > tr {
    display: flex;
    flex-direction: column;
  }
  .evolutions-table .stack-table td {
    margin-top: 0;
  }
  .stack-table.professional td:nth-of-type(1):before {
    content: "Profissional";
  }
  .stack-table.professional td:nth-of-type(2):before {
    content: "Tipo";
  }
  .stack-table.professional td:nth-of-type(3):before {
    content: "CRO";
  }
  .stack-table.professional td:nth-of-type(4):before {
    content: "Telefone";
  }
  .stack-table.treatments-listing td:nth-of-type(1):before {
    content: "Procedimento";
  }
  .stack-table.treatments-listing td:nth-of-type(2):before {
    content: "Valor";
  }
  .stack-table.treatments-listing td:nth-of-type(3):before {
    content: "Ativo";
  }
  .stack-table.treatments-listing td:nth-of-type(4):before {
    content: "Categoria";
  }
}
