@import 'mobile/stack-table';

@media screen and (max-width: 768px) {
  .tray {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: 1050;
  }
  .events-index-content {
    display: block;
  }
  #sidebar-left-min {
    display: none;
  }
  .fc-day-header.fc-widget-header {
    padding: 11px 5px;
  }
  .fc-toolbar.fc-header-toolbar {
    display: none;
  }
  .table-layout > div.modal {
    display: none;
  }
  .dentist-photo-image {
    width: 1.125rem;
    height: 1.125rem;
  }
  .modal-dialog {
    margin-bottom: 100px;
  }
  .patients table td:nth-child(2),
  .patients table th:nth-child(2),
  .patients table td:nth-child(3),
  .patients table th:nth-child(3) {
    display: none;
  }
  .patient.container {
    margin-bottom: 3.125rem;
  }
  .container.patient {
    padding-top: 0;
  }
  .uppy-Dashboard--modal {
    z-index: 1040;
  }
}
@media (hover: hover) and (pointer: fine) and (max-width: 520px) {
  .modal-patient .panel-heading {
    height: 55px;
  }
}
