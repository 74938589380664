.ui-datepicker--modern-theme.ui-datepicker {
  border-radius: 8px;
  border-width: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 17.75rem;
}
.ui-datepicker--modern-theme.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
  border: none !important;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-header {
  background-color: #fff;
  border-bottom: none;
  padding-bottom: 16px;
  padding-top: 18px;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-title {
  color: #111928;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-prev, .ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-next {
  align-items: center;
  color: rgb(107, 114, 128);
  display: flex;
  justify-content: center;
  padding: 0.625rem;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-prev:hover, .ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-next:hover {
  background-color: rgb(243, 244, 246);
  border-radius: 8px;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-prev .ui-icon {
  background-image: url("icons/chevron-left.svg");
  height: 1rem;
  width: 1rem;
  background-repeat: no-repeat;
  background-position: center;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-next .ui-icon {
  background-image: url("icons/chevron-right.svg");
  height: 1rem;
  width: 1rem;
  background-repeat: no-repeat;
  background-position: center;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-next-hover {
  background-color: rgb(243, 244, 246);
  border-radius: 8px;
}
.ui-datepicker--modern-theme.ui-datepicker th {
  color: #6B7280;
  font-size: 14px;
  font-weight: 600 !important;
}
.ui-datepicker--modern-theme.ui-datepicker td a {
  color: #111928;
  font-size: 14px;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-state-active {
  background-color: rgb(66, 153, 225) !important;
  border: 1px solid rgb(66, 153, 225);
  border-radius: 8px !important;
  color: #fff;
  padding: 0;
}
.ui-datepicker--modern-theme.ui-datepicker td a {
  line-height: 2.25rem;
  padding: 0;
}
.ui-datepicker--modern-theme.ui-datepicker td {
  padding: 0 !important;
}
.ui-datepicker--modern-theme.ui-datepicker td span:hover, .ui-datepicker--modern-theme.ui-datepicker td a:hover {
  background-color: rgb(243, 244, 246);
  border-radius: 8px;
  padding: 0;
  line-height: 2.25rem;
}
.ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-today a, .ui-datepicker--modern-theme.ui-datepicker .ui-datepicker-today a:hover, .ui-datepicker--modern-theme.ui-datepicker .ui-state-highlight {
  background-color: #fff !important;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  color: #111928;
  line-height: 2.25rem;
  padding: 0;
}

.ui-datepicker-inline.ui-datepicker .ui-datepicker-header {
  background-color: #fff;
  border-bottom: none;
  padding-bottom: 16px;
  padding-top: 18px;
}
.ui-datepicker-inline.ui-datepicker .ui-datepicker-title {
  color: #111928;
}
.ui-datepicker-inline.ui-datepicker .ui-datepicker-prev, .ui-datepicker-inline.ui-datepicker .ui-datepicker-next {
  align-items: center;
  color: rgb(107, 114, 128);
  display: flex;
  justify-content: center;
  padding: 0.625rem;
}
.ui-datepicker-inline.ui-datepicker .ui-datepicker-prev:hover, .ui-datepicker-inline.ui-datepicker .ui-datepicker-next:hover {
  background-color: rgb(243, 244, 246);
  border-radius: 8px;
}
.ui-datepicker-inline.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker-inline.ui-datepicker .ui-datepicker-next-hover {
  background-color: rgb(243, 244, 246);
  border-radius: 8px;
}
.ui-datepicker-inline.ui-datepicker .ui-datepicker-prev .ui-icon {
  background-image: url("icons/chevron-left.svg");
  height: 1rem;
  width: 1rem;
  background-repeat: no-repeat;
  background-position: center;
}
.ui-datepicker-inline.ui-datepicker .ui-datepicker-next .ui-icon {
  background-image: url("icons/chevron-right.svg");
  height: 1rem;
  width: 1rem;
  background-repeat: no-repeat;
  background-position: center;
}
.ui-datepicker-inline.ui-datepicker th {
  color: #6B7280;
}
.ui-datepicker-inline.ui-datepicker td a {
  color: #111928;
}
.ui-datepicker-inline.ui-datepicker .ui-state-active {
  background-color: rgb(66, 153, 225) !important;
  border: 1px solid rgb(66, 153, 225);
  border-radius: 8px !important;
  color: #fff;
  padding: 0;
}
.ui-datepicker-inline.ui-datepicker td a {
  border: 1px solid #fff;
  line-height: 1.7rem;
  padding: 0;
}
.ui-datepicker-inline.ui-datepicker td {
  padding: 0 !important;
}
.calendar-week-view .ui-datepicker-inline.ui-datepicker tr:hover a, .ui-datepicker-inline.ui-datepicker td span:hover, .ui-datepicker-inline.ui-datepicker td a:hover {
  border: 1px solid #e0e1e4;
  border-radius: 8px;
  line-height: 1.7rem;
  padding: 0;
  margin-left: 2px;
  margin-right: 2px;
}
.ui-datepicker-inline.ui-datepicker .ui-datepicker-today a, .ui-datepicker-inline.ui-datepicker .ui-datepicker-today a:hover, .ui-datepicker-inline.ui-datepicker .ui-state-highlight {
  background-color: #fff !important;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  color: #111928;
  line-height: 1.7rem;
  padding: 0;
}
