@charset "UTF-8";
/* ===========================
 Página de escolha pagamento
=========================== */
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  box-shadow: 0 1px 3px 0 #dbdbdb;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#credit-card-form label {
  margin-bottom: 10px;
  margin-top: 15px;
  font-weight: 400;
}

.form-pay {
  max-width: 460px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .form-pay {
    width: unset;
  }
}

.form-pay.establishment-info {
  max-width: 786px;
}

.error {
  display: none;
}

.error.visible {
  color: red;
  display: block;
}

.info-sub {
  text-align: center;
  font-weight: 100;
}

.form-pay .lead {
  text-align: center;
}

.title-pay {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* ===========================
 Página de escolha dos planos
========================== */
.price {
  float: left;
  display: flex;
}

.price .simbol {
  align-self: flex-start;
  margin-right: 8px;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  line-height: 3.5;
  font-weight: 100;
  color: #656565;
}

.price .value {
  font-family: "Gotham", sans-serif;
  font-size: 60px;
  margin-left: 10px;
  font-weight: 300;
}

.description-price {
  float: right;
  font-weight: 300;
  letter-spacing: normal;
  text-transform: none;
  font-family: "Gotham SSm", sans-serif;
  font-size: 13px;
  line-height: 2em;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 130px;
  flex: 0 0 130px;
  color: #999;
  margin-left: 20px;
  margin-top: 15px;
}

.plans-resume-list {
  margin-top: 20px;
  font-family: "Gotham SSm", sans-serif;
  font-size: 14px;
  line-height: 2em;
  color: #000;
}

.plans-features-list {
  margin-top: 20px;
}

.plans-features-list .lead {
  font-size: 15px;
}

.plans-features-list .lead span {
  margin-right: 10px;
  font-size: 13px;
}

.unlimetd-plan-box {
  padding-left: 70px;
}

.plans-page h4 {
  margin-top: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Gotham SSm", sans-serif;
  font-size: 13px;
  line-height: 1.66666667em;
  letter-spacing: 0.20833333em;
  color: #000;
}

@media (max-width: 991px) {
  .unlimetd-plan-box {
    padding-left: 0px;
    margin-top: 40px;
  }
}
.field_with_errors input {
  border: solid 1px red;
}

.field_with_errors .message {
  display: none !important;
}

.field.fswitch {
  margin-bottom: 15px;
}

.new-input-plan::-webkit-input-placeholder {
  color: #e0dede;
}

.new-input-plan::-moz-placeholder { /* Firefox 19+ */
  color: #e0dede;
}

.new-input-plan:-ms-input-placeholder { /* IE 10+ */
  color: #e0dede;
}

.new-input-plan:-moz-placeholder { /* Firefox 18- */
  color: #e0dede;
}

.dental-plan-set-default {
  display: inline-block;
  height: 30px;
  width: 196px;
  text-align: center;
  vertical-align: sub;
  margin-right: 20px;
}

.dental-plan-set-default label {
  font-weight: 600;
}

.dental-plan-set-default input {
  font-size: 17px;
}

.container.dental-plan-edit .category-plan strong {
  color: blue;
}

.container.dental-plan-edit table {
  table-layout: fixed;
}

.container.dental-plan-edit thead, .tfooter {
  display: table;
  width: 100%;
}

.container.dental-plan-edit tbody {
  height: calc(100vh - 340px);
  overflow: auto;
  overflow-x: hidden;
  display: block;
  width: 100%;
}

.container.dental-plan-edit tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.admin-form.theme-primary table input[type=text] {
  width: 100%;
  padding: 5px;
}

.container.dental-plan-edit .top-message-block .lead {
  font-size: 35px;
  float: left;
}

.container.dental-plan-edit .top-message-block .btn-read-all {
  float: right;
  line-height: 50px;
}

.container.dental-plan-edit .notification-listing {
  line-height: normal;
  border-radius: 3px;
  border-style: hidden;
  border-collapse: collapse;
  margin: 0 auto;
  margin-top: 20px;
  border-spacing: 0;
  table-layout: fixed;
  border: solid 1px #e4e4e4;
}

.container.dental-plan-edit .notification-listing span a {
  font-size: 12px;
  flex: all;
  text-decoration: none;
  margin-left: 40px;
}

.container.dental-plan-edit .delete-plan a {
  color: gray !important;
}

.container.dental-plan-edit table thead:first-child tr:first-child {
  background: #fafafa;
  font-weight: 600;
  font-size: 13px;
  color: gray;
}

.container.dental-plan-edit table tbody tr {
  padding: 1rem;
  border-top: 0px solid #f1f1f1;
  height: 70px;
}

.container.dental-plan-edit table tr td small {
  font-size: 75%;
}

.container.dental-plan-edit tr.inactive {
  background-color: rgba(249, 249, 249, 0.5098039216);
}

.container.dental-plan-edit .font-inactive {
  color: #908e8e;
}

.container.dental-plan-edit table thead tr td {
  padding: 10px;
}

.container.dental-plan-edit table tbody tr td {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.container.dental-plan-edit .icon-type {
  text-align: center;
  font-size: 20px;
  color: gray;
}

.container.dental-plan-edit .patient-resp {
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: proxima-nova, "proxima nova", "helvetica neue", helvetica, arial, sans-serif;
  font-weight: 400;
}

.container.dental-plan-edit .patient-resp .resp-text {
  color: green;
  font-weight: 600;
}

.container.dental-plan-edit .patient-resp .date-resp {
  line-height: 20px;
  margin-top: 5px;
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #bcbcbc;
}

.container.dental-plan-edit .not-read {
  background-color: #f9f9f9;
}

.container.dental-plan-edit table tbody tr td a {
  color: #246fdc;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
}

.container.dental-plan-edit .footer-message {
  text-align: right;
  margin-right: 40px;
}

.container.dental-plan-edit .pagination {
  padding-right: 21px;
}

.container.dental-plan-edit .delete-procedure {
  text-align: center;
}

.container.dental-plan-edit .delete-procedure a {
  color: #9e9e9e;
  font-size: 12px;
}
