.payment-by-professional .clinic .fa.fa-building {
  font-size: 18px;
  color: #556080;
}

.table.mbn.paymments tr td {
  padding: 7px;
}

.clinic-health .table.mbn.paymments tr td {
  padding: 12px;
}

.defaulters__value {
  color: #73879c;
  font-size: 24px;
  font-weight: 600;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
  text-align: center;
}

.defaulters__description {
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  margin-top: 3px;
  display: block;
}

.financial-overview {
  padding-top: 20px;
}

.clinic-health {
  margin-top: 40px;
}

.common-treatments th {
  color: #808a9a;
}

Cashflow .content-date-picker-calendar {
  float: left;
}

.content-date-picker-calendar i {
  margin-right: 10px;
}

.cashflow-toolbar {
  margin-bottom: 30px;
}

.filter-cashflow {
  background: #ffffff;
  border: solid 1px #dddddd;
  border-radius: 3px;
  left: 0;
  margin-top: 5px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  position: absolute;
  top: 34px;
  width: 250px;
}
@media screen and (max-width: 768px) {
  .filter-cashflow {
    left: 50%;
    transform: translateX(-50%);
  }
}

.filter-button {
  float: right;
  border: solid 1px #dadada;
  background: #eeeeee;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.btn-open-filter-financial i {
  margin-right: 5px;
  font-size: 16px;
  color: grey;
}

filter-block {
  display: block;
}

.filter-line-item {
  width: 100%;
}

.filter-line-full {
  width: 100%;
  padding-top: 3px;
}

.cash-filter-divisor {
  margin: 0px;
  margin-left: -20px;
  margin-right: -20px;
}

.filter-line {
  padding-top: 20px;
  padding-bottom: 20px;
}

.filter-line-button {
  background: #e3e8ee;
  margin-bottom: -10px;
  margin-left: -20px;
  margin-right: -20px;
  text-align: center;
  padding: 10px;
}

.filter-line-full input.btn.btn-primary,
.filter-line-full button.btn.btn-default {
  float: right;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-line-full button.btn.btn-default {
  float: left;
}

.cashflow-applied-filters-filter {
  margin-left: 5px;
}

.cashflow-applied-filters-clear {
  color: #a0aec0;
  margin-left: 5px;
  font-weight: bold;
  text-decoration: none;
}

.cashflow-applied-filters-clear:hover {
  color: #718096;
  text-decoration: none;
}

.cashflow-resume-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.cashflow-resume {
  color: #4a5568;
  display: flex;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.cashflow-resume-left {
  border-right: 1px solid #edf2f7;
}

.cashflow-resume-left,
.cashflow-resume-right {
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 10px 56px;
}

.cashflow-resume-done {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
}

.cashflow-resume-pending {
  font-size: 14px;
}

.cashflow-resume-green,
.cashflow-resume-red {
  float: right;
  margin-left: 30px;
}

.cashflow-resume-green {
  color: #38a169;
}

.cashflow-resume-red {
  color: #e53e3e;
}

.cashflow-expires-at-column-header,
.cashflow-expires-at-column-header:hover {
  color: inherit;
  text-decoration: none;
}

.content-table-cashflow {
  margin-top: 20px;
}

.content-table-cashflow .table.table-striped {
  border: solid 1px #f0f0f0;
}

.cashflow-export {
  float: right;
  margin-top: 19px;
  color: gray;
}

.btn-add-expense.btn.btn-hover.btn-success {
  float: right;
}

@media screen and (max-width: 485px) {
  .btn-add-expense {
    margin-top: 16px;
  }
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover,
.daterangepicker .ranges li {
  border: none;
}

.column-description i {
  margin-right: 12px;
}

.cashflow-patient-link {
  color: inherit;
}

.column-amount {
  width: 155px;
}

.column-amount span {
  display: inline-block;
  width: 95px;
  text-align: right;
}

.column-amount i {
  color: #bfbdbd;
  margin-left: 20px;
}

.title-column-amount {
  text-align: center;
}

.cashflow-patient-cpf {
  font-size: 0.9em;
  color: #848484;
  margin-left: 10px;
}

.payment-expired {
  color: #e53e3e;
}

.payment-today {
  color: green;
  font-weight: 500;
}

.payment-today small,
.payment-expired small {
  color: #b1b1b1;
  font-weight: 500;
}

.container.container-cashflow .content-table-cashflow .table > thead > tr > th,
.container.container-cashflow .content-table-cashflow .table > tbody > tr > th,
.container.container-cashflow .content-table-cashflow .table > tfoot > tr > th,
.container.container-cashflow .content-table-cashflow .table > thead > tr > td,
.container.container-cashflow .content-table-cashflow .table > tbody > tr > td,
.container.container-cashflow .content-table-cashflow .table > tfoot > tr > td {
  height: 65px;
}

.actions-table.secundary {
  width: 20px;
  text-decoration: none;
}

.actions-table.secundary a {
  color: #bdbdbd;
}

.actions-table.pay {
  width: 140px;
  text-align: center;
}

.actions-table.pay a {
  text-decoration: none;
  display: inline-block;
  /* background: #E3FCEC; */
  padding: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #3490dc;
  font-weight: 600;
  border: solid 1px #3490dc;
  border-radius: 5px;
}

.actions-table.pay a:hover {
  text-decoration: none;
  display: inline-block;
  background: #3490dc;
  padding: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #ffffff;
  font-weight: 600;
  border: solid 1px #3490dc;
  border-radius: 5px;
}

.column-date {
  width: 90px;
}

.column-paid {
  text-align: center;
  font-weight: 600;
  color: green;
}

.column-cancel-payment {
  text-align: right;
}

.column-cancel-payment a {
  text-decoration: none;
  color: #bdbdbd;
}

.financial-tooltip {
  display: block !important;
  z-index: 10000;
}

.financial-tooltip .tooltip-inner {
  background: rgba(97, 97, 97, 0.6588235294);
  color: white;
  border-radius: 4px;
  padding: 5px 10px 4px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  font-size: 12px;
  border: none;
}

.financial-tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
  border-color: #929292;
}

.financial-tooltip[x-placement^=top] {
  margin-bottom: 5px;
}

.financial-tooltip[x-placement^=top] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.financial-tooltip[x-placement^=bottom] {
  margin-top: 5px;
}

.financial-tooltip[x-placement^=bottom] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: 1px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.financial-tooltip[x-placement^=right] {
  margin-left: 5px;
}

.financial-tooltip[x-placement^=right] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.financial-tooltip[x-placement^=left] {
  margin-right: 5px;
}

.financial-tooltip[x-placement^=left] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.financial-tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.financial-tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.financial-tooltip[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.financial-tooltip[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.column-description small {
  color: #b1b1b1;
  font-weight: 500;
  display: block;
  margin-left: 30px;
}

.line-paid {
  background: #edfded;
}

a.link-new-category {
  float: right;
  text-decoration: none !important;
  color: #8c8c8c;
  margin-top: 5px;
  margin-bottom: -15px;
  font-style: oblique;
}

a.link-new-category:hover {
  text-decoration: none;
}

.loading-spinner {
  float: left;
  content: url("spinner.svg");
}

.loading span {
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}

.legend-chart {
  list-style: none;
  font-size: 12px;
  padding-left: 0;
}

.legend-chart li {
  padding-top: 20px;
  display: block;
}

.legend-chart span {
  float: left;
  width: 12px;
  height: 12px;
  margin: 3px 10px 3px 3px;
}

.chart-block.first {
  margin-top: 30px;
  display: block;
}

.chart-block {
  margin-top: 90px;
  display: block;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie {
  fill: #ffffff !important;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie {
  fill: #ffffff !important;
}

.ct-line {
  stroke-width: 3px;
}

.ct-point {
  stroke-width: 8px;
}

.chart-block.first.input-output .ct-series-a .ct-line {
  stroke: #51d88a;
}

.chart-block.first.input-output .ct-series-b .ct-line {
  stroke: #f66d9b;
}

.chart-block.first.input-output .ct-series-a .ct-point {
  stroke: #51d88a;
}

.chart-block.first.input-output .ct-series-b .ct-point {
  stroke: #f66d9b;
}

.chart-block.first.input-output .legend-chart .green {
  background-color: #51d88a;
}

.chart-block.first.input-output .legend-chart .red {
  background-color: #f66d9b;
}

.chart-block.approved-disapproved .ct-series-a .ct-line {
  stroke: #51d88a;
}

.chart-block.approved-disapproved .ct-series-b .ct-line {
  stroke: #6cb2eb;
}

.chart-block.approved-disapproved .ct-series-a .ct-point {
  stroke: #51d88a;
}

.chart-block.approved-disapproved .ct-series-b .ct-point {
  stroke: #6cb2eb;
}

.chart-block.approved-disapproved .legend-chart .green {
  background-color: #51d88a;
}

.chart-block.approved-disapproved .legend-chart .blue {
  background-color: #6cb2eb;
}

.chart-block.gain .ct-series-a .ct-line {
  stroke: #51d88a;
}

.chart-block.gain .ct-series-a .ct-point {
  stroke: #51d88a;
}

.chart-block.gain .legend-chart .green {
  background-color: #51d88a;
}

.chart-block.confirmations-and-faults .ct-series-a .ct-line {
  stroke: #51d88a;
}

.chart-block.confirmations-and-faults .ct-series-b .ct-line {
  stroke: #f66d9b;
}

.chart-block.confirmations-and-faults .ct-series-a .ct-point {
  stroke: #51d88a;
}

.chart-block.confirmations-and-faults .ct-series-b .ct-point {
  stroke: #f66d9b;
}

.chart-block.confirmations-and-faults .legend-chart .green {
  background-color: #51d88a;
}

.chart-block.confirmations-and-faults .legend-chart .red {
  background-color: #f66d9b;
}

.chart-block.average_ticket .ct-series-a .ct-line {
  stroke: #51d88a;
}

.chart-block.average_ticket .ct-series-a .ct-point {
  stroke: #51d88a;
}

.chart-block.average_ticket .legend-chart .green {
  background-color: #51d88a;
}

.chart-small-legend {
  float: right;
  margin-right: 20px;
}

.chart-block .lead {
  margin-left: -10px;
}

.chartist-tooltip {
  color: #ffffff;
  position: absolute;
  display: block;
  opacity: 0;
  min-width: 5em;
  padding: 10px 15px;
  background: rgba(128, 127, 126, 0.8);
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  border-radius: 10px;
}

.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -7px;
  border: 7px solid transparent;
  border-top-color: rgba(128, 127, 126, 0.8);
}

.financial-overview .panel-heading {
  height: 50px;
}

.financial-overview .content-date-picker-calendar {
  margin-top: 8px;
  padding: 5px 12px;
  background: #ffffff;
}

.financial-overview .panel-title {
  margin-top: 6px;
  display: inline-block;
}

.table.mbn.paymments.pl-24 td {
  padding-left: 24px !important;
}

.financial-overview .description-pay {
  width: 50%;
}

.financial-overview .percentage-pay {
  width: 25%;
  text-align: right !important;
}

.financial-select {
  border: 0;
  outline: 1px inset rgba(0, 0, 0, 0.1);
  outline-offset: -1px;
  color: #666666;
  background-color: rgba(255, 255, 255, 0.3882352941);
  height: 30px;
  margin-top: 10px;
  height: 29px;
  font-weight: 400;
}

.container.container-cashflow .content {
  margin-top: 50px;
}

.cashflow-list-empty {
  text-align: center;
  color: #676565;
  padding: 35px;
  font-size: 15px;
}

.main-title-financial-area {
  display: block;
  margin-bottom: 25px;
  margin-top: 10px;
}

.payments-for-professional-pagination {
  margin-left: 30px;
}

.payments-for-professional-no-debts {
  margin: 30px;
}

.payment-by-professional-last-colum {
  width: 50px;
  text-align: right;
}

.payment-by-professional-last-colum a {
  text-decoration: none;
  font-size: 11px;
  font-weight: 600;
}

.cashflow-receipt-list {
  color: gray;
  margin-left: 10px;
}

.exclamation-circle-delete-expenses {
  font-size: 85px;
}

.label-delete-expenses {
  font-size: 1.875em;
  font-weight: 600;
}
