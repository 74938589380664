@charset "UTF-8";
/*==================================================================
  Form Wrappers
===================================================================*/
.admin-form,
.admin-form * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.admin-form {
  line-height: 1.231;
  font-weight: 400;
  font-size: 14px;
  color: #626262;
}

.admin-form .section {
  margin-bottom: 22px;
}

/*==================================================================
  Browser Styling Reset
===================================================================*/
.admin-form .gui-input,
.admin-form .gui-textarea,
.admin-form .select > select,
.admin-form input[type=button],
.admin-form input[type=submit],
.admin-form input[type=search] {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.admin-form input[type=search]::-webkit-search-decoration,
.admin-form input[type=search]::-webkit-search-cancel-button,
.admin-form input[type=search]::-webkit-search-results-button,
.admin-form input[type=search]::-webkit-search-results-decoration {
  display: none;
}

/*==================================================================
  Prevent user selection. For usability purposes
===================================================================*/
.admin-form .radio,
.admin-form .button,
.admin-form .checkbox,
.admin-form .select .arrow,
.admin-form .switch > label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*==================================================================
  Input General Styles
===================================================================*/
.admin-form label,
.admin-form input,
.admin-form button,
.admin-form select,
.admin-form textarea {
  margin: 0;
  font-size: 14px;
  outline: none;
}

/*==================================================================
  Input Animations
===================================================================*/
.admin-form .radio,
.admin-form .button,
.admin-form .tooltip,
.admin-form .checkbox,
.admin-form .gui-input,
.admin-form .input-footer,
.admin-form .notification,
.admin-form .gui-textarea,
.admin-form .select > select {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  outline: none;
}

.admin-form .panel-heading,
.admin-form .section-divider span,
.admin-form .select > select {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/*===============================================
  Form Dividers
================================================= */
/*==================================================================
  Form Text Elements
===================================================================*/
/* Themed Link */
/* Section Divider */
.admin-form .section-divider {
  height: 0;
  border-top: 1px solid #ddd;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.admin-form .section-divider span {
  display: inline-block;
  position: relative;
  padding: 0 17px;
  background: #e9e9e9;
  color: #999;
  top: -11px;
  font-size: 15px;
}

.admin-form.panel .section-divider span,
.admin-form .panel .section-divider span,
.admin-form.tab-content .section-divider span,
.admin-form .tab-content .section-divider span {
  background: #fff;
}

.admin-form.bg-light .section-divider span,
.admin-form .bg-light .section-divider span {
  background: #fafafa;
}

.admin-form.bg-light.light .section-divider span,
.admin-form .bg-light.light .section-divider span {
  background: #fefefe;
}

.admin-form.bg-light.dark .section-divider span,
.admin-form .bg-light.dark .section-divider span {
  background: #f2f2f2;
}

.admin-form.well .section-divider span,
.admin-form .well .section-divider span {
  background: #f5f5f5;
}

/* Input Labels */
.admin-form .switch,
.admin-form .option,
.admin-form .field-label {
  font-size: 14px;
}

/* Column Labels - Can also use .field-align */
.admin-form .field-label[class*=col-] {
  line-height: 42px;
}

/*==================================================================
  General Input Styling
===================================================================*/
.admin-form .radio,
.admin-form .option,
.admin-form .checkbox {
  cursor: pointer;
}

.admin-form .field {
  display: block;
  position: relative;
}

.admin-form .field-icon i {
  color: #bbb;
  position: relative;
}

.admin-form .field-label {
  display: block;
  margin-bottom: 7px;
}

.admin-form .field-label em {
  color: #e74c3c;
  font-size: 14px;
  font-style: normal;
  display: inline-block;
  margin-left: 4px;
  position: relative;
  top: 3px;
}

.admin-form .gui-input,
.admin-form .gui-textarea {
  padding: 10px;
}

.admin-form .select,
.admin-form .gui-input,
.admin-form .gui-textarea,
.admin-form .select > select {
  position: relative;
  vertical-align: top;
  border: 1px solid #ddd;
  display: -moz-inline-stack;
  display: inline-block;
  *display: inline;
  color: #626262;
  outline: none;
  height: 42px;
  width: 100%;
  *zoom: 1;
}

.admin-form .select option {
  background: #fff;
}

.admin-form select[disabled],
.admin-form .select > select[disabled] {
  color: #aaa !important;
}

/*==================================================================
 Form Textarea
===================================================================*/
.admin-form .gui-textarea {
  resize: none;
  line-height: 19px;
  overflow: auto;
  max-width: 100%;
  height: 96px;
}

/*==================================================================
  Form Selects
===================================================================*/
.admin-form .select {
  border: 0;
  z-index: 10;
  background: #fff;
}

.admin-form .select > select {
  display: block;
  z-index: 10;
  margin: 0;
  padding: 9px 10px;
  color: #626262;
  outline: none;
  text-indent: 0.01px;
  text-overflow: "";
  background: #fff;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: normal;
}

.admin-form .select:before,
.admin-form .select:after {
  background: #fff;
}

.admin-form .select > select::-ms-expand {
  display: none;
}

.admin-form .select .arrow {
  pointer-events: none;
  position: absolute;
  top: 9px;
  right: 4px;
  width: 24px;
  height: 24px;
  color: #9f9f9f;
  z-index: 100;
}

.admin-form .select .arrow:after,
.admin-form .select .arrow:before {
  content: "";
  position: absolute;
  font: 12px "Consolas", monospace;
  font-style: normal;
  pointer-events: none;
  display: none\9 ;
  left: 5px;
}

.admin-form .select .arrow:before {
  content: "▼";
  bottom: 4px;
}

.admin-form .select .double:after {
  content: "▲";
  top: -1px;
}

.admin-form .select .double:before {
  content: "▼";
  bottom: -1px;
}

/*==================================================================
  Form File Inputs
===================================================================*/
.admin-form .file {
  display: block;
  width: 100%;
}

/* Default state positions button right */
.admin-form .file .button {
  position: absolute;
  top: 4px;
  right: 4px;
  float: none;
  height: 34px;
  line-height: 34px;
  padding: 0 16px;
  z-index: 10;
}

/* File Button - Left Style */
/*==================================================================
  Form Inputs:hover state
-===================================================================*/
.admin-form .gui-input:hover,
.admin-form .gui-textarea:hover,
.admin-form .select > select:hover,
.admin-form .gui-input:hover ~ .input-footer,
.admin-form .gui-textarea:hover ~ .input-footer {
  border-color: #888;
}

/*==================================================================
  Form Inputs:focus state
===================================================================*/
.admin-form .gui-input:focus,
.admin-form .gui-textarea:focus,
.admin-form .select > select:focus {
  color: #3c3c3c;
  background: #fff;
  border: 1px solid #999;
  outline: none;
}

.admin-form .select > select:focus {
  z-index: 10;
  z-index: 20\9 ;
}

.admin-form .gui-textarea:focus {
  height: 120px;
}

.admin-form .select > select:focus {
  z-index: 10;
  z-index: 20\9 ;
}

.admin-form .gui-input:focus ~ .field-icon i,
.admin-form .gui-textarea:focus ~ .field-icon i {
  color: #999;
}

.admin-form .gui-input:focus ~ .input-footer,
.admin-form .gui-textarea:focus ~ .input-footer {
  border-color: #999;
}

.admin-form .select > select:focus + .arrow {
  color: #999;
}

.admin-form .select.select--small {
  height: 35px;
}
.admin-form .select.select--small select {
  height: 35px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.admin-form .select.select--small .arrow {
  top: 4px;
}

/*==================================================================
  Radio/Checkboxes
-===================================================================*/
.admin-form .option {
  position: relative;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.admin-form .option > input {
  position: absolute;
  height: inherit;
  width: inherit;
  opacity: 0;
  left: 0;
}

.admin-form .checkbox,
.admin-form .radio {
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  background: #fff;
  display: inline-block;
  border: 3px solid #ddd;
  height: 21px;
  width: 21px;
  top: 4px;
}

.admin-form .checkbox:before,
.admin-form .radio:before {
  content: "";
  display: none;
}

.admin-form input:checked + .checkbox:before,
.admin-form input:checked + .radio:before {
  display: block;
}

.admin-form .checkbox:before {
  position: absolute;
  top: 4px;
  left: 3px;
  width: 6px;
  height: 3px;
  border: solid #888;
  border-width: 0 0 3px 3px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.admin-form input:checked + .checkbox,
.admin-form input:checked + .radio {
  border: 3px solid #888;
}

.admin-form .radio {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}

.admin-form .radio:before {
  margin: 4px;
  width: 7px;
  height: 7px;
  background: #888;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

/*==================================================================
  Radio/Checkboxes:hover state
===================================================================*/
.admin-form input:not([disabled]):hover + .checkbox,
.admin-form input:not([disabled]):hover + .radio {
  border-color: #666;
}

/*==================================================================
  Radio/Checkboxes:focus state
-===================================================================*/
.admin-form input:focus + .checkbox,
.admin-form input:focus + .radio {
  border-color: #666;
}

.admin-form input:focus + .radio:before {
  background: #666;
}

.admin-form input:focus + .checkbox:before {
  border-color: #666;
}

/*==================================================================
  Switches
===================================================================*/
.admin-form .switch {
  cursor: pointer;
  position: relative;
  padding-right: 10px;
  display: inline-block;
  margin-bottom: 5px;
  height: 26px;
}

.admin-form .switch > label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  height: 25px;
  width: 58px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  background: #d7d7d7;
  border: 2px solid #d7d7d7;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.admin-form .switch > label + span {
  display: inline-block;
  padding-left: 5px;
  position: relative;
  top: -7px;
}

.admin-form .switch > label:before {
  content: attr(data-off);
  position: absolute;
  top: 1px;
  right: 3px;
  width: 33px;
}

.admin-form .switch > label:after {
  content: "";
  margin: 1px;
  width: 19px;
  height: 19px;
  display: block;
  background: #fff;
}

.admin-form .switch > input {
  -webkit-appearance: none;
  position: absolute;
  width: inherit;
  height: inherit;
  opacity: 0;
  left: 0;
  top: 0;
}

/*==================================================================
  Switch:focus state
-===================================================================*/
.admin-form .switch > input:focus {
  outline: none;
}

.admin-form .switch > input:focus + label {
  color: #fff;
  border-color: #c7c7c7;
  background: #c7c7c7;
}

.admin-form .switch > input:focus + label:after {
  background: #fff;
}

/*==================================================================
  Switch:checked state
===================================================================*/
.admin-form .switch > input:checked + label {
  border-color: #999;
  background: #999;
  padding-left: 33px;
  color: white;
}

.admin-form .switch > input:checked + label:before {
  content: attr(data-on);
  left: 1px;
  top: 1px;
}

.admin-form .switch > input:checked + label:after {
  margin: 1px;
  width: 19px;
  height: 19px;
  background: white;
}

/*==================================================================
  Switch:checked:focus state
-===================================================================*/
.admin-form .switch > input:checked:focus + label {
  background: #555;
  border-color: #555;
}

/* Round Style*/
/*==================================================================
  Switch - Custom Success/Danger Styled
-===================================================================*/
/* Checked state */
/*==================================================================
  Input:disabled state
===================================================================*/
.admin-form .button[disabled],
.admin-form input[disabled] + .radio,
.admin-form input[disabled] + .checkbox,
.admin-form .switch > input[disabled] + label {
  cursor: default;
  opacity: 0.5;
}

.admin-form .gui-input[disabled],
.admin-form .gui-textarea[disabled],
.admin-form .select > select[disabled],
.admin-form .gui-input[disabled] ~ .input-footer,
.admin-form .gui-textarea[disabled] ~ .input-footer {
  background-color: #f4f6f6;
  border-color: #d5dbdb !important;
  cursor: default;
  color: #333;
  opacity: 0.7;
}

.admin-form input[disabled] ~ .field-icon i,
.admin-form textarea[disabled] ~ .field-icon i,
.admin-form .select > select[disabled] + .arrow {
  opacity: 0.4;
}

/*==================================================================
  Form Footer
===================================================================*/
.admin-form .input-footer {
  padding: 10px;
  display: block;
  margin-top: -1px;
  line-height: 16px;
  position: relative;
  background: #f5f5f5;
  border: 1px solid #ddd;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  font-size: 11px;
  color: #999;
}

/*==================================================================
  Input Icons
===================================================================*/
.admin-form .prepend-icon {
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
}

.admin-form .prepend-icon .field-icon {
  top: 0;
  z-index: 4;
  width: 42px;
  height: 42px;
  color: inherit;
  line-height: 42px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  pointer-events: none;
}

.admin-form .prepend-icon .field-icon i {
  position: relative;
  font-size: 14px;
}

.admin-form .prepend-icon .field-icon {
  left: 0;
}

.admin-form .prepend-icon > input,
.admin-form .prepend-icon > textarea {
  padding-left: 36px;
}

.admin-form .field_with_errors .prepend-icon .field_with_errors > input,
.admin-form .field_with_errors .prepend-icon > textarea {
  padding-left: 36px;
}

/*==================================================================
  Input Tooltips
===================================================================*/
.admin-form .tooltip {
  position: absolute;
  z-index: -1;
  opacity: 0;
  color: #fff;
  width: 184px;
  left: -9999px;
  top: auto;
  font-size: 11px;
  font-weight: normal;
  background: #333333;
  -webkit-transition: margin 0.6s, opacity 0.6s;
  -moz-transition: margin 0.6s, opacity 0.6s;
  -ms-transition: margin 0.6s, opacity 0.6s;
  -o-transition: margin 0.6s, opacity 0.6s;
  transition: margin 0.6s, opacity 0.6s;
}

.admin-form .tooltip > em {
  padding: 12px;
  font-style: normal;
  display: block;
  position: static;
}

.admin-form .tooltip:after {
  content: "";
  position: absolute;
}

.admin-form .gui-input:focus + .tooltip,
.admin-form .gui-textarea:focus + .tooltip {
  opacity: 1;
  z-index: 999;
}

/*==================================================================
  Tooltip - Left
===================================================================*/
/*==================================================================
  Tooltip - Right
===================================================================*/
/*==================================================================
  Tooltip - Right/Top
===================================================================*/
/*==================================================================
  Tooltip - Left/Top
===================================================================*/
/*==================================================================
  Tooltip - Right/Bottom
===================================================================*/
/*==================================================================
  Tooltip - Left/Bottom
===================================================================*/
/*==================================================================
  Validation - error state
===================================================================*/
/*==================================================================
  Validation - success state
===================================================================*/
/*==================================================================
  Smart Widget
  * These widgets helps us to position an element, button,
  * label or span. The positions can either be left or right
  * while the input stays 100%. Use this to rapidly create
  * search widgets, newsletter subscribe etc .
====================================================================*/
/* Disabled Prepended Icon State */
/*==================================================================
  Smart Widget Button - Left
===================================================================*/
/*==================================================================
  Smart Widget Button - Right
===================================================================*/
/*==================================================================
  Rating Star Widget - with hover
-===================================================================*/
/*==================================================================
  Buttons
-===================================================================*/
.admin-form .button {
  color: #243140;
  border: 0;
  height: 42px;
  line-height: 42px;
  font-size: 15px;
  cursor: pointer;
  padding: 0 18px;
  text-align: center;
  vertical-align: top;
  background: #dbdbdb;
  display: inline-block;
  -webkit-user-drag: none;
  text-shadow: 0 1px rgba(255, 255, 255, 0.2);
}

/*==================================================================
  Button States
===================================================================*/
.admin-form .button:hover {
  color: #243140;
  background: #eee;
}

.admin-form .button:active {
  color: #1d2938;
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.admin-form a.button,
.admin-form span.button,
.admin-form label.button {
  line-height: 42px;
  text-decoration: none;
}

.admin-form .button i {
  font-size: 14px;
}

/*==================================================================
  Primary Button
-===================================================================*/
.admin-form .btn-primary {
  background-color: #3bafda;
}

.admin-form .btn-primary:hover,
.admin-form .btn-primary:focus {
  background-color: #3bafda;
}

.admin-form .btn-primary:active {
  background-color: #3bafda;
}

.admin-form .btn-primary,
.admin-form .btn-primary:hover,
.admin-form .btn-primary:focus,
.admin-form .btn-primary:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}

/*==================================================================
  Left + Right Shaped Buttons
-===================================================================*/
/*==================================================================
  Buttons - Push Effect
-===================================================================*/
/*==================================================================
  Buttons - Footer Adjustment
-===================================================================*/
/*==================================================================
  Buttons - Social
===================================================================*/
.admin-form .twitter,
.admin-form .twitter:hover,
.admin-form .twitter:focus,
.admin-form .facebook,
.admin-form .facebook:hover,
.admin-form .facebook:focus {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}

.admin-form .facebook {
  background-color: #3b5998;
}

.admin-form .twitter {
  background-color: #00acee;
}

.admin-form .facebook:hover,
.admin-form .facebook:focus {
  background-color: #25385f;
}

.admin-form .twitter:hover,
.admin-form .twitter:focus {
  background-color: #00749f;
}

.admin-form .twitter span {
  background-color: #009ad5;
}

.admin-form .facebook span {
  background-color: #31497d;
}

/*==================================================================
  Lists
-===================================================================*/
/*==================================================================
  Firefox select fix - select arrow hack disabled on FF 30+
===================================================================*/
@-moz-document url-prefix() {
  .admin-form .select:before {
    content: "";
    pointer-events: none;
    -moz-transition: none;
    transition: none;
    position: absolute;
    background: #f5f5f5;
    width: 36px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: 99;
  }
  .admin-form .select > select:focus,
  .admin-form .select > select:hover,
  .admin-form .select:hover select,
  .admin-form .select:hover:before {
    background: #fff;
    -moz-transition: none;
    transition: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .admin-form .select .arrow {
    z-index: 100;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .admin-form .select .arrow:after,
  .admin-form .select .arrow:before {
    display: block;
  }
}
/*==================================================================
  ANDROID + IOS FIXES
===================================================================*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .admin-form .option,
  .admin-form .switch {
    -webkit-animation: bugfix infinite 1s;
  }
  @-webkit-keyframes bugfix {
    from {
      padding: 0;
    }
    to {
      padding: 0;
    }
  }
  .admin-form .switch {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .admin-form .option {
    margin-right: 15px;
  }
}
/*==================================================================
  Responsiveness for Tablets + Smartphones
===================================================================*/
@media (max-width: 600px) {
  .admin-form .section row {
    margin: 0;
  }
  .admin-form .border-right {
    border-right: 0;
  }
}
/*==================================================================
  Stepper - jQuery UI
===================================================================*/
/*==================================================================
  Datepicker - jQuery UI
===================================================================*/
.ui-datepicker {
  width: 18em;
  margin-top: 8px;
  display: none;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  z-index: 1025 !important;
  text-align: center;
  color: #666;
  min-height: 200px;
}

.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
  border: 1px solid #DDD !important;
}

.hasDatepicker .ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
  border: none !important;
}

.ui-datepicker a {
  color: #404040;
  text-align: center;
}

.ui-datepicker .ui-state-disabled span {
  color: #dbdbdb;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  background: #f5f5f5;
  border-bottom: 1px solid #cfcfcf;
  line-height: 27px;
  font-size: 15px;
  padding: 10px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  width: 34px;
  height: 34px;
  display: block;
  font-size: 14px;
  position: absolute;
  text-decoration: none;
  cursor: pointer;
  color: #666;
  top: 20.5%;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
  color: #555;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
  padding: 1px 5px;
  background-color: #eee;
  border-color: #ccc;
}

.ui-datepicker .ui-datepicker-title select option {
  background: #fff;
}

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 52%;
}

.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  margin: 0 0 15px;
  border-collapse: collapse;
}

.ui-datepicker th {
  padding: 0.5em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 2px 5px;
}

.ui-datepicker td span,
.ui-datepicker td a {
  color: #aaa;
  padding: 0.25em;
  display: block;
  text-align: center;
  text-decoration: none;
}

.ui-datepicker td span:hover,
.ui-datepicker td a:hover {
  background: #d3d3d3;
  border-radius: 30px;
}

.ui-datepicker .ui-state-disabled span:hover {
  background: none;
}

.ui-datepicker-today a,
.ui-datepicker-today a:hover,
.ui-datepicker .ui-state-active,
.ui-datepicker .ui-state-highlight {
  font-weight: 600;
  color: #fff;
  background: #ccc !important;
}

.ui-datepicker .ui-state-active {
  background: #AECBFA !important;
}

/* jQuery UI Buttons */
.ui-datepicker-buttonpane {
  border-top: 1px solid #ddd;
  padding: 10px;
  background: #f8f8f8;
}

.ui-datepicker-buttonpane button {
  padding: 5px 12px;
  margin-right: 10px;
  font-size: 13px;
  position: relative;
  line-height: normal;
  display: inline-block;
  -webkit-user-drag: none;
  text-shadow: 0 1px rgba(255, 255, 255, 0.2);
  vertical-align: middle;
  background: #fff;
  text-align: center;
  overflow: visible;
  cursor: pointer;
  color: #333;
  border: 1px solid #ddd;
}

/* jQuery UI Button States */
.ui-datepicker-buttonpane button:hover {
  color: #243140;
  background: #e8e8e8;
}

.ui-datepicker-buttonpane button:active {
  color: #1d2938;
  background: #c4c4c4;
}

/* Inline Picker */
.ui-datepicker-inline {
  width: 100%;
}

.ui-datepicker-inline {
  box-shadow: none;
  margin: 0 auto;
}

/*==================================================================
  Monthpicker - jQuery UI
===================================================================*/
/*==================================================================
  Multi-Month Datepicker - jQuery UI
===================================================================*/
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.333%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

/*==================================================================
  TimePicker - jQuery UI
===================================================================*/
/* jQuery Slider Settings when inside a timepicker widget */
/*==================================================================
  Slider - jQuery UI
===================================================================*/
/* progress bar strips */
/*==================================================================
  Slider Tooltips - jQuery UI
===================================================================*/
/*==================================================================
  Spectrum Color Picker
===================================================================*/
/* expands container to the left of
 * button, default opens right */
/* Custom Changes - Field preview thumb */
/* Custom Changes - Primary Container */
/* Color Pointer */
/* hue + color border color */
/* Hue Slider */
/* Custom Changes - Palette Container */
/* palette thumbs */
/* palette active thumb */
/*==================================================================
  Google Map Block
===================================================================*/
.admin-form .block {
  display: block;
}

/*==================================================================
  AdminForm Wizard
===================================================================*/
/* form wizard wrapper */
/* Wizard top nav - "steps" */
/* Nav item states */
/* Disable various nav meta information */
/* Wizard Content */
/* Wizard Actions/Footer */
/* hide steps icons */
/* hide steps icons */
/* steps align right  */
/* steps align right  */
/* steps justified */
/* steps tabs */
/* steps add BG */
/*==================================================================
  Panel
===================================================================*/
.admin-form .panel {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
}

/*==================================================================
  Panel Header
===================================================================*/
.admin-form .panel-heading {
  overflow: hidden;
  position: relative;
  height: auto;
  padding: 19px 22px 18px;
  color: #999;
  border-radius: 0;
  border-top: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #ddd;
  background-color: #fafafa;
}

.admin-form .heading-border:before {
  content: "";
  background-color: #9999a3;
  position: absolute;
  height: 10px;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
}

.admin-form .heading-border .panel-heading {
  padding: 30px 22px 17px;
}

.admin-form .heading-border .panel-title {
  color: #999;
}

.admin-form .panel-title {
  text-align: left;
  font-weight: 300;
  font-size: 26px;
  padding: 0;
  margin: 0;
  background: transparent;
}

.admin-form .panel-title i {
  font-size: 26px;
  position: relative;
  margin-right: 15px;
  top: 0;
  border-width: 0;
}

/*==================================================================
  Panel Body
===================================================================*/
.admin-form .panel-body {
  padding: 25px;
  border: 0;
}

/*==================================================================
  Panel Footer
===================================================================*/
.admin-form .panel-footer {
  padding: 12px 13px;
  border: 0;
  border-top: 1px solid #ddd;
  background: #f2f2f2;
}

/*==================================================================
  Progress Bars
===================================================================*/
/*==================================================================
  Checkbox/Radio Confliction fixes
===================================================================*/
.admin-form .radio,
.admin-form .checkbox {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  top: 1px;
  vertical-align: bottom;
}

.admin-form .checkbox:after,
.admin-form .checkbox:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
}

/* This file creates and combines all of the AdminForm color skins.
 * To minimize file size, we recommended using only one or two skins.
 * However, we have provided this file as it proves useful for demonstrating
 * all of skins or for enabling true multi-skin support.
 */
.admin-form .panel-primary > .panel-heading {
  border-top-color: #5d9cec;
}

.admin-form .panel-primary > .panel-heading:before {
  background-color: #4a89dc;
}

.admin-form .panel-primary.heading-border:before,
.admin-form .panel-primary .heading-border:before {
  background-color: #4a89dc;
}

.admin-form .bg-primary {
  background-color: #4a89dc;
}

.admin-form .bg-primary.light {
  background-color: #5d9cec;
}

.admin-form .bg-primary.dark {
  background-color: #2e76d6;
}

.admin-form .switch-primary > input:checked + label {
  background: #4a89dc;
  border-color: #4a89dc;
}

.admin-form .switch-primary > input:checked + label:after {
  color: #4a89dc;
}

.admin-form .switch-primary > input:checked:focus + label {
  background: #2e76d6;
  border-color: #2e76d6;
}

.admin-form .btn-primary {
  background-color: #4a89dc;
}

.admin-form .btn-primary:hover,
.admin-form .btn-primary:focus {
  background-color: #5d9cec;
  border-color: #5d9cec;
}

.admin-form .btn-primary:active {
  background-color: #2e76d6;
}

.admin-form .btn-primary,
.admin-form .btn-primary:hover,
.admin-form .btn-primary:focus,
.admin-form .btn-primary:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}

.admin-form.theme-primary .section-divider span {
  color: #4a89dc;
}

.admin-form.theme-primary .gui-input:hover,
.admin-form.theme-primary .gui-textarea:hover,
.admin-form.theme-primary .select > select:hover,
.admin-form.theme-primary .gui-input:hover ~ .input-footer,
.admin-form.theme-primary .gui-textarea:hover ~ .input-footer {
  border-color: #5d9cec;
}

.admin-form.theme-primary .gui-input:focus,
.admin-form.theme-primary .gui-textarea:focus,
.admin-form.theme-primary .select > select:focus {
  border-color: #5d9cec;
  color: #3c3c3c;
}

.admin-form.theme-primary .gui-textarea:focus {
  height: 120px;
}

.admin-form.theme-primary .select > select:focus {
  z-index: 10;
  z-index: 20\9 ;
}

.admin-form.theme-primary .gui-input:focus ~ .field-icon i,
.admin-form.theme-primary .gui-textarea:focus ~ .field-icon i {
  color: #4a89dc;
}

.admin-form.theme-primary .gui-input:focus ~ .input-footer,
.admin-form.theme-primary .gui-textarea:focus ~ .input-footer {
  border-color: #5d9cec;
}

.admin-form.theme-primary .select > select:focus + .arrow {
  color: #4a89dc;
}

.admin-form.theme-primary .ui-datepicker .ui-datepicker-prev,
.admin-form.theme-primary .ui-datepicker .ui-datepicker-next,
.admin-form.theme-primary .ui-datepicker .ui-datepicker-title {
  color: #4a89dc;
}

.admin-form.theme-primary .ui-datepicker-today a,
.admin-form.theme-primary .ui-datepicker-today a:hover,
.admin-form.theme-primary .ui-datepicker .ui-state-highlight {
  background: #9fc1ed !important;
}

.admin-form.theme-primary .ui-datepicker .ui-state-active {
  background: #4a89dc !important;
}

.admin-form.theme-primary .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #fff !important;
}

.admin-form.theme-primary .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #4a89dc;
}

.admin-form.theme-primary .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #4a89dc;
}

.admin-form .panel-info > .panel-heading {
  border-top-color: #4fc1e9;
}

.admin-form .panel-info > .panel-heading:before {
  background-color: #3bafda;
}

.admin-form .panel-info.heading-border:before,
.admin-form .panel-info .heading-border:before {
  background-color: #3bafda;
}

.admin-form .bg-info {
  background-color: #3bafda;
}

.admin-form .bg-info.light {
  background-color: #4fc1e9;
}

.admin-form .bg-info.dark {
  background-color: #27a0cc;
}

.admin-form.theme-info .section-divider span {
  color: #3bafda;
}

.admin-form.theme-info .gui-input:hover,
.admin-form.theme-info .gui-textarea:hover,
.admin-form.theme-info .select > select:hover,
.admin-form.theme-info .gui-input:hover ~ .input-footer,
.admin-form.theme-info .gui-textarea:hover ~ .input-footer {
  border-color: #4fc1e9;
}

.admin-form.theme-info .gui-input:focus,
.admin-form.theme-info .gui-textarea:focus,
.admin-form.theme-info .select > select:focus {
  border-color: #4fc1e9;
  color: #3c3c3c;
}

.admin-form.theme-info .gui-textarea:focus {
  height: 120px;
}

.admin-form.theme-info .select > select:focus {
  z-index: 10;
  z-index: 20\9 ;
}

.admin-form.theme-info .gui-input:focus ~ .field-icon i,
.admin-form.theme-info .gui-textarea:focus ~ .field-icon i {
  color: #3bafda;
}

.admin-form.theme-info .gui-input:focus ~ .input-footer,
.admin-form.theme-info .gui-textarea:focus ~ .input-footer {
  border-color: #4fc1e9;
}

.admin-form.theme-info .select > select:focus + .arrow {
  color: #3bafda;
}

.admin-form.theme-info .ui-datepicker .ui-datepicker-prev,
.admin-form.theme-info .ui-datepicker .ui-datepicker-next,
.admin-form.theme-info .ui-datepicker .ui-datepicker-title {
  color: #3bafda;
}

.admin-form.theme-info .ui-datepicker-today a,
.admin-form.theme-info .ui-datepicker-today a:hover,
.admin-form.theme-info .ui-datepicker .ui-state-highlight {
  background: #91d2ea !important;
}

.admin-form.theme-info .ui-datepicker .ui-state-active {
  background: #3bafda !important;
}

.admin-form.theme-info .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle.ui-state-active {
  background: #fff !important;
}

.admin-form.theme-info .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle {
  border-color: #3bafda;
}

.admin-form.theme-info .ui-datepicker .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  background-color: #3bafda;
}

.admin-form .panel-success > .panel-heading {
  border-top-color: #87d37c;
}

.admin-form .panel-success > .panel-heading:before {
  background-color: #70ca63;
}

.admin-form .panel-success.heading-border:before,
.admin-form .panel-success .heading-border:before {
  background-color: #70ca63;
}

.admin-form .bg-success {
  background-color: #70ca63;
}

.admin-form .bg-success.light {
  background-color: #87d37c;
}

.admin-form .bg-success.dark {
  background-color: #58c249;
}

.admin-form .btn-success {
  background-color: #70ca63;
}

.admin-form .btn-success:hover,
.admin-form .btn-success:focus {
  background-color: #87d37c;
  border-color: #87d37c;
}

.admin-form .btn-success:active {
  background-color: #58c249;
}

.admin-form .btn-success,
.admin-form .btn-success:hover,
.admin-form .btn-success:focus,
.admin-form .btn-success:active {
  color: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.08);
}

.admin-form .panel-warning > .panel-heading {
  border-top-color: #ffce54;
}

.admin-form .panel-warning > .panel-heading:before {
  background-color: #f6bb42;
}

.admin-form .panel-warning.heading-border:before,
.admin-form .panel-warning .heading-border:before {
  background-color: #f6bb42;
}

.admin-form .bg-warning {
  background-color: #f6bb42;
}

.admin-form .bg-warning.light {
  background-color: #ffce54;
}

.admin-form .bg-warning.dark {
  background-color: #f4af22;
}

.admin-form .panel-danger > .panel-heading {
  border-top-color: #fc6e51;
}

.admin-form .panel-danger > .panel-heading:before {
  background-color: #e9573f;
}

.admin-form .panel-danger.heading-border:before,
.admin-form .panel-danger .heading-border:before {
  background-color: #e9573f;
}

.admin-form .bg-danger {
  background-color: #e9573f;
}

.admin-form .bg-danger.light {
  background-color: #fc6e51;
}

.admin-form .bg-danger.dark {
  background-color: #e63c21;
}

.admin-form .panel-alert > .panel-heading {
  border-top-color: #ac92ec;
}

.admin-form .panel-alert > .panel-heading:before {
  background-color: #967adc;
}

.admin-form .panel-alert.heading-border:before,
.admin-form .panel-alert .heading-border:before {
  background-color: #967adc;
}

.admin-form .bg-alert {
  background-color: #967adc;
}

.admin-form .bg-alert.light {
  background-color: #ac92ec;
}

.admin-form .bg-alert.dark {
  background-color: #815fd5;
}

.admin-form .panel-system > .panel-heading {
  border-top-color: #48cfad;
}

.admin-form .panel-system > .panel-heading:before {
  background-color: #37bc9b;
}

.admin-form .panel-system.heading-border:before,
.admin-form .panel-system .heading-border:before {
  background-color: #37bc9b;
}

.admin-form .bg-system {
  background-color: #37bc9b;
}

.admin-form .bg-system.light {
  background-color: #48cfad;
}

.admin-form .bg-system.dark {
  background-color: #2fa285;
}

.admin-form .panel-dark > .panel-heading {
  border-top-color: #424759;
}

.admin-form .panel-dark > .panel-heading:before {
  background-color: #3b3f4f;
}

.admin-form .panel-dark.heading-border:before,
.admin-form .panel-dark .heading-border:before {
  background-color: #3b3f4f;
}

.admin-form .bg-dark {
  background-color: #3b3f4f;
}

.admin-form .bg-dark.light {
  background-color: #424759;
}

.admin-form .bg-dark.dark {
  background-color: #2c2f3c;
}

.ui-datepicker-next-hover, .ui-datepicker-prev-hover {
  background: #e0e0e0;
  border-radius: 50px;
}
