.actions-professional-list {
  text-align: center;
}

.actions-professional-list i {
  font-size: 15px;
}

.actions-professional-list .fa.fa-trash {
  margin-left: 20px;
  color: #c4c4c4;
}

.image-and-name {
  color: #222;
}

.image-and-name img {
  width: 25px;
  margin-right: 13px;
  float: left;
  margin-top: 6px;
}

.image-and-name span {
  display: block;
  color: grey;
}

.professional-table-list tr {
  height: 70px;
}

.table.professional thead {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: #888;
}

.content-professional-modal {
  padding: 20px;
  margin-top: 18px;
  border: 1px solid rgba(150, 150, 150, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow linear 40ms, border-color linear 0.2s;
  padding-top: 8px;
  margin-bottom: 0px;
}

.professional-title-sub {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #888;
  margin-bottom: -11px;
  margin-top: 15px;
}

.professional-title-sub.margin {
  margin-top: 15px;
  margin-bottom: -6px;
}

.col-md-3.top-pading.admin .fa.fa-info-circle {
  color: gray;
  margin-left: 5px;
}

.col-md-12.input-email .gui-input.disabled {
  color: #616363;
}
