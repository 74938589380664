.websites {
  font-family: "Inter", sans-serif;
}

@media (min-width: 768px) {
  .website-step {
    min-height: calc(100vh - 133px);
  }
}

.website-inactive-page #content {
  padding-left: 24px;
  padding-right: 24px;
}

.ui-radio-input:checked {
  background-image: url("data:image/svg+xml,%3Csvg height='10' width='10' viewBox='0 0 16 16' fill='%231570EF' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E") !important;
}

.ui-checkbox-input:checked {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L4.5 8.5L2 6' stroke='%231570EF' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
  background-size: 12px 12px !important;
}

.ui-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

.ui-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #EAECF0;
}

.ui-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #EAECF0;
}
