.evolutions-table-cell-content__edit-button {
  display: none;
}
.evolutions-table-cell-content:hover .evolutions-table-cell-content__edit-button {
  display: block;
}

.evolutions-table-cell-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@media screen and (max-width: 450px) {
  .evolutions-buttons-container {
    display: grid !important;
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .evolutions-buttons-container .evolutions-buttons-container__digitally-sign-btn {
    grid-column: span 2/span 2 !important;
  }
}
@media screen and (min-width: 451px) {
  .evolutions-buttons-container {
    display: flex !important;
  }
}
