.popular {
  right: -2px;
  top: -2px;
  z-index: 1;
  width: 76px;
  height: 75px;
}

.popular span {
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 97px;
  -webkit-box-shadow: 0 3px 10px -5px #000;
  box-shadow: 0 3px 10px -5px #000;
  top: 19px;
  right: -28px;
  padding-left: 21px;
}

.recurring-purchase-modal {
  padding: 0;
  background-color: #ffffff;
  margin-bottom: 0;
}

.recurring-purchase-modal .panel-primary.heading-border:before {
  opacity: 0;
}

.recurring-purchase-modal .panel-body {
  padding-top: 0;
}

.recurring-purchase-modal .admin-form .panel {
  margin-bottom: 0;
}

.recurring-purchase-modal .save-input {
  color: #ffffff;
}

.recurring-purchase-modal select {
  padding: 6px;
}

.recurring-purchase-modal .sms-package-label {
  padding-top: 10px;
  padding-right: 5px;
}

.recurring-purchase-modal .sms-number-label {
  width: 10%;
}

.recurring-purchase-button {
  float: right;
  margin-top: -15px;
}

.purchase-completed-recurring-purchase-warning {
  background-color: rgb(239, 246, 255);
}
