.custom-progress {
  border-radius: 8px;
  height: 12px;
}

.custom-progress::-webkit-progress-bar {
  border-radius: 8px;
  background-color: rgb(237, 242, 247);
  width: 100%;
}

/* background: */
.custom-progress::-webkit-progress-bar {
  border-radius: 8px;
  background-color: rgb(237, 242, 247);
  width: 100%;
}

progress {
  background-color: rgb(237, 242, 247);
}

/* value: */
.custom-progress::-webkit-progress-value {
  border-radius: 8px;
  background-color: #4a89dc !important;
}

.custom-progress::-moz-progress-bar {
  border-radius: 8px;
  background-color: #4a89dc !important;
}
