@import "trix/dist/trix";

/* Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
 the trix-editor content (whether displayed or under editing). Feel free to incorporate this
 inclusion directly in any other asset bundle and remove this file.

 We need to override trix.css’s image gallery styles to accommodate the
 <action-text-attachment> element we wrap around attachments. Otherwise,
 images in galleries will be squished by the max-width: 33%; rule. */
.trix-content .attachment-gallery > action-text-attachment,
.trix-content .attachment-gallery > .attachment {
  flex: 1 0 33%;
  padding: 0 0.5em;
  max-width: 33%;
}
.trix-content .attachment-gallery.attachment-gallery--2 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--2 > .attachment, .trix-content .attachment-gallery.attachment-gallery--4 > action-text-attachment,
.trix-content .attachment-gallery.attachment-gallery--4 > .attachment {
  flex-basis: 50%;
  max-width: 50%;
}
.trix-content action-text-attachment .attachment {
  padding: 0 !important;
  max-width: 100% !important;
}

/* trix */
.trix-button--icon-increase-nesting-level,
.trix-button--icon-decrease-nesting-level,
.trix-button--icon-attach,
.trix-button--icon-heading-1,
.trix-button--icon-quote,
.trix-button--icon-code,
.trix-button-group--file-tools {
  display: none !important;
}

trix-editor {
  height: 14rem !important;
  overflow-y: auto !important;
}

trix-editor > ul, trix-editor > ol {
  padding: 0 18px !important;
}

trix-editor > ul, .trix-content ul {
  list-style: disc !important;
}

trix-editor > ol, .trix-content ol {
  list-style: decimal !important;
}

@media (max-width: 768px) {
  trix-toolbar .trix-button-group-spacer {
    display: inline !important;
  }
  trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 12px;
  }
}
trix-editor {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: #ced0d4;
}

trix-toolbar {
  border: 1px solid #ced0d4;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0;
}

trix-toolbar .trix-button-group {
  margin-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
}

.trix-button {
  padding-top: 6px;
  padding-bottom: 10px;
}

button.trix-button {
  padding-bottom: 12px;
  padding-top: 12px;
}

trix-toolbar .trix-button--icon {
  height: unset;
}

trix-toolbar .trix-button--icon::before {
  background-size: 1.4rem;
}

span.trix-button-group {
  border: 0;
  border-bottom: 0;
}

trix-toolbar .trix-button {
  border-width: 0;
}

button.trix-button.trix-button--icon.trix-button--icon-number-list {
  border-left-width: 0;
}

trix-toolbar .trix-button:not(:first-child) {
  border-left: 0;
}
