.new-event-type-radios {
  margin-top: 20px;
  margin-left: 18px;
}

.new-event-type-radios input,
.new-event-type-radios label {
  cursor: pointer;
}

.new-event-type-radios input {
  margin-right: 3px;
}

.new-event-type-radios-compromise {
  margin-left: 20px;
}

.nice-select {
  width: 100%;
  margin-bottom: 17px;
}

.nice-select .current {
  font-size: 14px;
  color: #666666;
  font-weight: 600;
}

#professional-modal-list .nice-select .current {
  margin-left: -16px;
}

#professional-modal-list.nice-select.current {
  margin-left: -16px;
}

#professional-modal-list .nice-select .option {
  padding-left: 0px;
}

.nice-select .current:before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  border-radius: 20px;
}

.admin-form .nice-select .option {
  display: block;
  margin-right: 0;
}
.admin-form .nice-select .current:before {
  width: 0;
}

#current_scheduled:before {
  background-color: #448bd1;
}

#current_confirmed:before {
  background-color: #58ca7a;
}

#current_finished:before {
  background-color: #a0a0a0;
}

#current_missed:before {
  background-color: #f3534a;
}

#current_canceled_by_the_patient:before {
  background-color: #f3534a;
}

#current_canceled_by_the_professional:before {
  background-color: #f3534a;
}

.nice-select ul {
  width: 100%;
}

.list li:before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 5px;
  border-radius: 20px;
}

.list li[data-value=scheduled]:before {
  background-color: #478ad6;
}

.list li[data-value=confirmed]:before {
  background-color: #65ca70;
}

.list li[data-value=finished]:before {
  background-color: #a0a0a0;
}

.list li[data-value=missed]:before,
.list li[data-value=canceled_by_the_patient]:before,
.list li[data-value=canceled_by_the_professional]:before {
  background-color: #ee5545;
}

#current_patient_waiting:before,
.list li[data-value=patient_waiting]:before {
  background-color: #f8c969;
}

#current_patient_attendance:before,
.list li[data-value=patient_attendance]:before {
  background-color: #6b21a8;
}

.show-patient-info {
  text-align: right;
  display: block;
  margin-bottom: -10px;
  text-decoration: none;
  margin-top: 3px;
  font-size: 12px;
  color: #3182ce;
}

.show-patient-info a {
  text-decoration: none;
}

.nice-select .list {
  max-height: 330px;
  overflow-y: scroll;
  border-radius: 0px;
  margin-top: 1px;
  border: solid 1px #f2f2f2;
  border-top: none;
}

.suggestion-of-times-row {
  margin-top: 25px;
}

.appointments-row {
  margin-top: 5px;
}

@media screen and (min-width: 992px) {
  .appointment-extra-fields--return-other .select-return-patient {
    width: 41.66666667%;
  }
  .appointment-extra-fields--indication-active .appointment-send-reminder {
    margin-top: -14px;
    width: 25%;
  }
  .appointment-extra-fields--indication-active .select-return-patient {
    width: 37.5%;
  }
  .appointment-extra-fields--indication-active .appointment-patient-indication {
    width: 37.5%;
  }
  .appointment-extra-fields--return-other.appointment-extra-fields--indication-active .appointment-patient-indication {
    width: 33.3333%;
  }
  .appointment-extra-fields--return-other.appointment-extra-fields--indication-active .select-return-patient {
    width: 25%;
  }
}
.appointments-row .select > select {
  height: 39px;
}

.fc-printCalendar-button {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.fc-printCalendar-button:hover {
  border-radius: 999px;
  padding-left: 6px;
  padding-right: 6px;
}
.fc-printCalendar-button .fc-icon-print {
  color: rgb(113, 128, 150);
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  opacity: 1;
  text-rendering: auto;
  transform: translate(0, 0);
  transition: opacity 0.1s ease-in;
}
.fc-printCalendar-button .fc-icon-print:before {
  content: "\f02f";
}

.events-index #section-to-print {
  display: none;
}
@media print {
  .events-index #content {
    display: none;
  }
  .events-index * {
    visibility: hidden;
  }
  .events-index #section-to-print {
    display: block;
  }
  .events-index #section-to-print, .events-index #section-to-print * {
    visibility: visible;
  }
  .events-index #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.patients-waiting-container {
  color: #667085;
}

.fc-event__observations-icon {
  float: right;
  margin-right: 2px;
}

/* Will show a loading spinner while the image is loading */
.event-tooltip__patient-photo {
  background-image: url("spinner.svg");
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  position: relative;
}

/* Before will show if the image is broken */
.event-tooltip__patient-photo:before {
  background-color: #fff;
  border-radius: 50px;
  background-image: url("spinner.svg");
  background-repeat: no-repeat;
  background-position: center;
  content: " ";
  display: block;
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
}

.event-creation-info {
  font-size: 10px;
}
