@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import "../../fonts/font-awesome/font-awesome.css";
@import "./application.css";
@import '../twenty_twenty_three/components/internal-chat.css';
@import './components/toggle.css';
@import './components/alert.css';
@import 'floating-vue/dist/style.css';
@import './components/register.css';

@tailwind base;
/* Tailwind */
@tailwind components;

@layer base{
  a{
    color: inherit;
  }

  .text-shadow-none{
    text-shadow: none!important;
  }
}

.btn {
  @apply tw-font-semibold tw-rounded tw-cursor-pointer;
}

.btn:hover {
  @apply tw-no-underline;
}

.btn-tertiary {
  @apply tw-bg-transparent tw-py-2 tw-px-4 tw-font-semibold;
}

.btn-tertiary:hover {
  @apply tw-text-gray-900 tw-no-underline;
}

.btn-tertiary-red {
  @apply tw-text-red-600 tw-bg-transparent tw-py-2 tw-px-4 tw-font-semibold;
}

.btn-tertiary-red:hover {
  @apply tw-text-red-800 tw-no-underline;
}

.btn-blue, button[type="submit"].btn-blue {
  @apply tw-bg-blue-500 tw-text-white;
}

.btn-blue:hover, button[type="submit"].btn-blue:hover{
  @apply tw-bg-blue-600 tw-text-white;
}


.btn-blue-secondary {
  @apply tw-bg-transparent tw-text-blue-500 tw-border tw-border-blue-300;
}

.btn-blue-secondary:hover {
  @apply tw-border-blue-400 tw-text-blue-600;
}

.btn-green {
  @apply tw-bg-green-500 tw-text-white;
}

.btn-green:hover {
  @apply tw-bg-green-600 tw-text-white;
}

.btn-blue-strong,.btn-blue-strong--with-icon {
  @apply tw-text-white tw-bg-[#1570EF] tw-text-base ;
}

.btn-blue-strong--with-icon{
 @apply tw-flex tw-gap-2 tw-items-center;
}

.btn-blue-strong:hover {
  @apply tw-bg-[#175CD3] tw-transition-colors;
}

.btn-dropdown {
  @apply tw-bg-white tw-text-gray-600 tw-py-1 tw-px-2 tw-border tw-border-gray-400 tw-rounded;
}

.btn-dropdown:hover {
  @apply tw-border-gray-500 tw-text-gray-700;
}

.form-input{
  border-style: solid;
}

.btn-primary {
  color: #ffffff;
  background-color: #4a89dc;
}

.btn-success {
  color: #ffffff;
  background-color: #70ca63;
}

.auth-title-container{
  top: -9.875rem;
  position: absolute;
}

.auth-recover-title-container{
  top: -11.8125rem;
  position: absolute;
}

.auth-new-password-title-container{
  top: -8.0625rem;
  position: absolute;
}

@media screen and (max-height: 700px){
  .auth-title-container{
    position: relative;
    top: unset;
    @apply tw-mb-8;
  }

  .auth-recover-title-container, .auth-new-password-title-container{
    position: relative;
    top: unset;
    @apply tw-mb-8;
  }
}

.dropdown-item.selected{
  @apply tw-bg-blue-200;
}

.cta-insights{
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(19,19,19,1) 100%);
  @apply tw-px-4 tw-py-1 tw-text-xs tw-flex tw-fixed tw-top-0 tw-text-center tw-w-full tw-left-0 tw-h-min tw-justify-center tw-text-blue-100;
  z-index: 1030;
}

.cta-websites{
  @apply tw-bg-ublue-800 tw-px-4 tw-py-1 tw-text-sm tw-fixed tw-top-0 tw-text-center tw-w-full tw-left-0 tw-text-white;
  z-index: 1030;
}

@tailwind utilities;
.insights-blocks-container{
  @apply tw-auto-rows-fr tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-8 sm:tw-grid-cols-2  sm:tw-gap-x-6 md:tw-grid-cols-3 lg:tw-grid-cols-3 2xl:tw-grid-cols-4 xl:tw-gap-x-8 tw-mt-6;
}

.insights-block{
  @apply tw-border-solid tw-border tw-border-gray-200 tw-rounded tw-shadow-sm tw-pt-2 tw-flex tw-flex-col tw-justify-between tw-relative;
}

.insights-block__tooltip{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.insights-block__name{
  @apply tw-text-sm tw-px-2;
}

.insights-block__primary-number{
  @apply tw-text-2xl tw-text-center tw-px-2;
  font-weight: 600;
}

.insights-block__attendance-level{
  @apply tw-text-center tw-font-bold tw-uppercase tw-my-2 ;
}

.insights-block__attendance-level-too-bad, .insights-block__attendance-level-bad{
  @apply tw-text-red-400;
}

.insights-block__attendance-level-normal{
  @apply tw-text-gray-400;
}

.striped-table tbody tr:nth-child(even) {
  @apply tw-bg-gray-50;
}

.insights-block__attendance-level-good, .insights-block__attendance-level-very-good, .insights-block__attendance-level-great{
  @apply tw-text-green-400;
}

.insights-block__average-level{
  @apply tw-p-1 tw-rounded-br tw-rounded-bl tw-px-2 tw-w-full tw-flex tw-items-center tw-mt-4 tw-justify-center;
}

.insights-block__average-level-good{
  @apply tw-bg-green-200 tw-text-green-800;
}

.insights-block__average-level-normal{
  @apply tw-bg-gray-200 tw-text-gray-800;
}

.insights-block__average-level-bad{
  @apply tw-bg-red-200 tw-text-red-800;
}

.ui-datepicker td.date-occupancy-level a{
  border-radius: 8px!important;
  line-height: 1.7rem;
  padding: 0;
  margin-left: 2px;
  margin-right: 2px;
}

.ui-datepicker td.date-occupancy-level[data-occupancy-level="low"] a{
  @apply tw-bg-green-100;
  @apply tw-text-green-900;
}

.ui-datepicker td.date-occupancy-level[data-occupancy-level="moderate"] a{
  @apply tw-bg-green-200/50;
  @apply tw-text-green-900;
}

.ui-datepicker td.date-occupancy-level[data-occupancy-level="middle"] a{
  @apply tw-bg-yellow-200/50;
  @apply tw-text-yellow-900;
}

.ui-datepicker td.date-occupancy-level[data-occupancy-level="high"] a{
  @apply tw-bg-orange-200/70;
  @apply tw-text-orange-900;

}

.ui-datepicker td.date-occupancy-level[data-occupancy-level="full"] a{
  @apply tw-bg-red-200/50;
  @apply tw-text-red-900;
}
.ui-datepicker-inline.ui-datepicker td.date-occupancy-level.ui-datepicker-current-day a{
  color: #ffffff;
}

.ui-datepicker-inline.ui-datepicker td.date-occupancy-level.ui-datepicker-today a{
  color: #111928;
}

.empty-state__title {
  @apply  tw-font-medium tw-text-base tw-text-gray-900 tw-text-center;
}
.empty-state__description {
  @apply  tw-text-center tw-text-xs tw-text-gray-500 ;
}
.empty-state__image {
  @apply tw-mx-auto tw-max-w-full ;

}
.empty-state__image--medium {
  @apply tw-w-72 ;
}

.empty-state__image--notifications {
  @apply tw-w-40;
}

.empty-state__image--big {
  @apply tw-w-96 ;
}

@layer utilities {
  .scrollbar-thin {
    scrollbar-color: #718096 white;
    scrollbar-width: thin;
  }
  .scrollbar-thin::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }
  .scrollbar-thin::-webkit-scrollbar-track {
    background-color: white;
  }
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #718096;
    border-radius: 5px;
  }
}

/* patients view */

.patients-view-container {
  @apply  lg:tw-flex tw-pl-0;
}

.patients-view-container__sidebar {
  @apply  lg:tw-w-[17%] ;
}

.patients-view-container__content {
  @apply md:tw-flex-1 tw-ml-2 lg:tw-pl-5 lg:tw-pr-3 tw-w-full;

}

/* _profile component */

.patients-profile-divider {
  @apply tw-text-lg tw-text-gray-500 tw-hidden md:tw-block first:tw-hidden last:tw-hidden;
}

.primary--input {
  @apply focus:!tw-ring-[#F4EBFF] focus:!tw-border-[#84CAFF] !tw-outline-none
    !tw-ring !tw-ring-transparent tw-border tw-border-ugray-300 !tw-rounded-lg
    tw-px-3.5 tw-py-2.5 placeholder:tw-text-ugray-500 !tw-text-base
    placeholder:tw-text-base tw-leading-6 tw-shadow-sm tw-w-full tw-text-ugray-900;
}

.primary--label {
  @apply tw-font-medium tw-text-sm tw-leading-5 !tw-mb-0;
}

.container--label-input {
  @apply tw-flex tw-flex-col tw-gap-1.5 tw-mb-4;
}

.btn-blue-strong,.btn-blue-strong--with-icon {
  @apply tw-text-white !tw-bg-ublue-700 tw-text-base
    tw-rounded-lg tw-px-[18px] tw-py-2.5 tw-font-medium
    hover:!tw-bg-ublue-800 tw-transition-colors
    disabled:tw-bg-ublue-700 disabled:tw-opacity-60;
}

.btn-blue-strong--with-icon{
  @apply tw-flex tw-gap-2 tw-items-center tw-justify-center;
}

.upload-created-date {
  @apply tw-top-[86%] tw-justify-center tw-h-min
                      tw-font-medium tw-text-white tw-opacity-100 tw-text-sm
                      tw-z-10;
}

.upload-created-date__modal {
  @apply  tw-z-10 tw-truncate tw-w-full tw-mt-2 tw-text-base;
}

@media screen and (min-width: 832px) {
 .toggle-theme__banner {
    @apply tw-left-1/2 tw-translate-x-[-50%] tw--translate-y-[3%] tw-w-[700px];
  }
}

@media screen and (min-width: 1023px) and (max-width: 1153px) {
 .navbar-branding {
    @apply  tw-mr-[27px];
  }
}

.trix-button.trix-button--dialog[data-trix-method="removeAttribute"] {
  @apply tw-hidden;
}

.font--base {
  font-family: Inter, Helvetica, Roboto, Arial, sans-serif;
}
