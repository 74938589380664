.content.signup-block {
  background-color: #f3f3f3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.signup .logo {
  text-align: center;
}

.signup .logo img {
  width: 200px;
  padding-top: 40px;
  margin: 0 auto;
}

.signup h1 {
  font-size: 28px;
  font-weight: 400;
  color: #2a2f40;
  margin-bottom: 15px;
  text-align: center;
  font-family: "Heebo";
  margin-bottom: 5px;
}

.signup .lead {
  text-align: center;
  font-size: 18px;
  color: grey;
  margin-bottom: 50px;
}

.signup .box-new-account {
  background-color: #fff;
  max-width: 550px;
  margin: 0 auto;
  word-wrap: break-word;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #bfbfbf;
  padding: 40px;
  position: relative;
}

.signup .box-new-account:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -17px;
  border: solid rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 17px;
  z-index: 0;
  pointer-events: none;
}

.info-top-box {
  font-size: 18px;
  font-family: "Heebo";
  font-weight: 300;
  margin-bottom: 20px;
}

.info-top-box.l-first {
  margin-bottom: 5px;
}

.signup .label-input {
  margin-bottom: 0px;
}

.input-signup {
  width: 100%;
  height: 50px;
  border-radius: 1px;
  border: solid 1px #80bcf9;
  font-size: 18px;
  margin-top: 5px;
  padding-left: 10px;
  border-radius: 4px;
  font-family: monospace;
}

.input-signup.input-first {
  margin-bottom: 20px;
}

.btn-steps-signup {
  margin-top: 20px;
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  white-space: normal;
  cursor: pointer;
  background-color: #3490dc !important;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  font-family: "Heebo";
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.subtitle-lead {
  margin-bottom: 10px;
  display: block;
}

.subtitle-lead.s-first {
  margin-bottom: 20px;
}

.signup #error_explanation {
  background: #ffffff;
  padding: 19px;
  max-width: 550px;
  margin: 0 auto;
  margin-bottom: 15px;
  font-family: "Heebo";
  border-radius: 5px;
  border: solid 1px #f38282;
}

.signup #error_explanation ul li {
  list-style: none;
  font-size: 15px;
}

.signup #error_explanation h2 {
  margin-top: 0px;
  font-weight: 500;
  font-size: 18px;
}

.signup .steps {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 28px;
}

.steps p {
  text-align: center;
  display: initial;
  padding: 2px;
  color: #fff;
  font-weight: 600;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-top: 5px;
  color: #4792e2;
  border: solid 1px;
}

.registration-sign-up-page--multiple-steps .show-error {
  color: red;
  margin-top: -5px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
}

#step-2, #step-3 {
  margin-right: 42px;
}
