.top-pading {
  padding-top: 20px;
}

.table-patient {
  width: 100%;
}

.table-patient tr {
  height: 30px;
}

.title-table {
  font-weight: 600;
  color: gray;
}

.description-table {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  letter-spacing: 0.01em;
}

.danger-patient {
  color: #da2f2f;
  font-size: 18px;
}

.danger-description {
  color: #da2f2f;
  font-size: 18px;
  margin-left: 6px;
}

.full-width-div {
  position: absolute;
  width: 100%;
  left: 0;
}

.link-header-patient a {
  color: gray;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}

.link-header-patient a.active {
  border-bottom: solid 2px rgb(33, 150, 243);
  color: rgb(33, 150, 243);
  padding-bottom: 3px;
}

.medias {
  overflow: auto;
  max-height: 300px;
  padding: 0 15px;
  background: #f4f5f7;
}

.chat-widget .media {
  margin-bottom: 2px;
  color: #6d6d6d;
}
.chat-widget .media-out {
  margin-right: 35%;
}
.chat-widget .media-in {
  margin-left: 35%;
}
.chat-widget .media-in .media-body {
  border: 1px solid #e4f3d3;
  background-color: #effdde;
}
.chat-widget .media-in .media-body:after {
  border-left-color: #effdde;
}
.chat-widget .media-body {
  border-radius: 4px;
}
.chat-widget .media-left,
.chat-widget .media-right,
.chat-widget .media-body {
  display: block;
}
.chat-widget .media-left {
  padding-right: 0;
  background-color: #effdde;
}
.chat-widget .media-right {
  padding-left: 0;
}
.chat-widget .media-body {
  width: 100%;
}

.basic-info.sub {
  margin-top: 0px;
  color: #636262;
}

.content-right.history {
  padding-left: 0px;
  padding-right: 0px;
  border-left: solid 1px #c8c9cc;
  border-right: solid 1px #c8c9cc;
  border-bottom: solid 1px #c8c9cc;
}

.patient-observations {
  border-bottom: solid 1px #eae7e7;
  padding-bottom: 20px;
}

.content-right {
  padding: 0px 20px 0px 30px;
}

.anamnese-alert__icon {
  color: #F79009;
  font-size: 17px;
  margin-right: 4px;
}

.anamnese-alert {
  background-color: #FFFAEB;
  border-radius: 5px;
  color: #B54708;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.04em;
  padding: 5px 6px;
  text-align: center;
  white-space: nowrap;
}

@media screen and (min-width: 1024px) {
  .anamnese-alert {
    margin-left: 12px;
  }
}
.patient-overview__name-container {
  width: calc(100% - 129px - 107px);
}

.basic-info-top {
  margin-left: 30px;
  margin-top: 16px;
  color: #adadad;
}

.basic-info {
  margin-left: 30px;
  margin-top: 25px;
  color: #adadad;
  margin-bottom: 20px;
}

.basic-a-side {
  width: 50%;
  float: left;
  border-right: 1px solid #eae7e7;
  border-bottom: 1px solid #eae7e7;
  height: 100px;
}

.basic-b-side {
  width: 50%;
  float: left;
  border-bottom: 1px solid #eae7e7;
  height: 100px;
}

.basic-b-side h2 {
  font-size: 16px;
  text-align: center;
  margin-top: 16px;
}

.basic-a-side h2 {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: -8px;
  text-align: center;
}

.basic-a-side .lead {
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}

.tab-block {
  margin-right: 1px;
}

.tab-block .nav-tabs > li.active > a,
.tab-block .nav-tabs > li.active > a:hover,
.tab-block .nav-tabs > li.active > a:focus {
  border-bottom: inherit;
}

.patient-right-side {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: solid 1px #ced0d4;
}

.main-title {
  background: #f5f6f7;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -11px;
  border-bottom: solid 1px #e2e0e0;
  padding-top: 10px;
  padding-left: 30px;
}

.main-title p {
  font-size: 14px;
  font-weight: 600;
  color: #606f86;
}

.patient-right-side-content {
  border: solid 1px #eae7e7;
  padding: 0px;
}

.subtitle-patient {
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: inherit;
  border-bottom: solid 1px #c8c9cc;
  padding-bottom: 8px;
  display: block;
  color: #585c63;
}

.subtitle-patient span.edit-link {
  float: right;
  font-size: 10px;
  line-height: 24px;
}

.col-md-7.line {
  border-right: solid 1px #e2e0e0;
}

.table-patient {
  width: 100%;
}

.table-patient tr {
  height: 35px;
}

.patient-btn-left-side {
  text-align: center;
  margin-bottom: 40px;
}

.patient-btn-left-side button {
  width: 110px;
}

.patient-photo-link {
  min-width: 85px;
  position: relative;
  width: 85px;
  height: 85px;
  margin: 0 auto;
  display: block;
}

@media screen and (min-width: 768px) {
  .patient-photo-link {
    margin: 0;
  }
}
.patient-photo-hover {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 9999px;
}

.patient-photo-hover:hover {
  background: rgba(0, 0, 0, 0.53);
}

.patient-photo-hover i,
.patient-photo-hover span {
  display: none;
  position: absolute;
}

.patient-photo-hover span {
  top: 47px;
  left: 17px;
  color: #d8d5d5;
  font-weight: 800;
  font-size: 11px;
}

.patient-photo-hover i {
  top: 23px;
  left: 28px;
  font-size: 18px;
  color: #d8d5d5;
}

.patient-photo-hover:hover i,
.patient-photo-hover:hover span {
  display: block;
}

.no-image.patient-photo-image {
  opacity: 0.5;
  filter: opacity(0.5);
}

.patient-photo-image {
  width: 75px;
  height: 75px;
  border-radius: 9999px;
}

.dentist-photo-image {
  width: 22px;
  height: 22px;
  border-radius: 9999px;
}

.patient-full-name h2 {
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
}

.patient-full-name {
  border-bottom: dashed 1px #d2d2d2;
  margin-bottom: 10px;
}

.patient-left-side {
  color: rgb(66, 82, 110);
  display: flex;
  flex-direction: column;
}

.patient-left-side label {
  color: #777474;
  font-weight: 350;
  font-size: 13px;
}

.patient-details {
  padding-left: 10px;
  padding-right: 10px;
  word-break: break-word;
}

.info-patient-left {
  font-size: 14px;
  letter-spacing: 0.02em;
}

.top-left-side {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 30px;
}

.initial {
  border-radius: 50%;
  background-color: #428af9;
  color: #fff;
  font-size: 26px;
  width: 30px;
  height: 30px;
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tooltip.in {
  opacity: 1 !important;
}

.tooltip-inner {
  background: rgba(66, 66, 66, 0.7803921569);
  color: white;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  font-size: 13px;
  border: none;
  font-weight: 500;
  text-align: left;
  max-width: 450px;
}

.tooltip-inner ol {
  margin-bottom: 6.5px;
  margin-top: 5.5px;
}

.footer-patient-left {
  padding: 7px;
  text-align: right;
  background: whitesmoke;
  margin-left: -11px;
  margin-right: -11px;
  margin-top: 30px;
}

.patient-history-message .panel-footer {
  background-color: initial;
  border: none;
  padding: 20px 0 0 0;
}

.patient-full-name p.subtitle .phone {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.8;
  color: gray;
  margin-top: 0px;
  text-align: center;
  display: block;
  margin-right: 0px !important;
}

.phone {
  display: block;
}

.subtitle span {
  margin-right: 10px;
  color: #556080;
  font-size: 14px;
}

.patient-menu ul {
  list-style: none;
}
@media screen and (max-width: 769px) {
  .patient-menu ul {
    margin-left: 0;
  }
}

.patient-menu ul li {
  line-height: 60px;
  padding-left: 15px;
}

.patient-menu ul li.active {
  background: #f7f7f7;
  border-left: solid 5px #66708d;
  padding-left: 9px;
}

.patient-menu ul li.active a {
  font-weight: 600;
}

.patient-menu ul li a {
  color: #556080;
  display: block;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}

.patient-menu ul li a small {
  font-size: 9px;
}

.patient-menu ul li a span {
  margin-right: 10px;
}

.patient-sidebar-footer p {
  color: #a7a6a6;
  font-size: 11px;
  margin-top: 20px;
}

.basic-info-patient {
  line-height: 40px;
  border-bottom: solid 1px #ebedf0;
  padding: 10px;
}

.basic-info-patient .basic-title {
  width: 30%;
  color: #6b6e73;
  float: left;
  font-size: 14px;
  font-weight: normal;
}

.basic-info-patient .basic-content {
  font-size: 13px;
  letter-spacing: 0.02em;
  color: #42526e;
}

.content-right.history .panel-body {
  padding: 0px !important;
}

form .panel-footer {
  padding: 0px;
  border: none;
}

#message_body {
  height: 50px;
  border: none;
}

.btn.btn-default.btn-gradient {
  height: 55px;
  border: none;
}

button[type=submit] {
  background-color: white;
}

button[type=submit] img {
  width: 25px;
}

.evolution-description {
  font-size: 13px;
  letter-spacing: 0.02em;
  color: #42526e;
  line-height: 20px;
}

.evolution-description.none {
  color: grey;
  padding-top: 15px;
  padding-bottom: 13px;
}

.evolution-dentist {
  color: gray;
  float: left;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 170px;
  margin-bottom: -10px;
}

.evolution-date {
  margin-top: -10px;
  color: gray;
  display: inline-block;
}

.basic-info-patient img {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 480px;
  margin-left: -10px;
}

.age-patient {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 5px;
}

.basic-content.now-rap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.basic-info-list .basic-info-patient {
  line-height: 25px;
}

.history-consulting {
  font-size: 13px;
  letter-spacing: 0.02em;
  color: #42526e;
}

.history-consulting .basic-info-patient {
  white-space: nowrap;
}

.basic-info-list {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-procedures {
  display: block;
  color: #bababa;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.link-remove {
  color: #505050;
  text-decoration: none;
  font-weight: 600;
}

.date-tooltip {
  color: #929090;
}

.hr-absolute {
  margin: 8px -8px 8px -8px;
}

.tooltip-header {
  margin-right: -8px;
  margin-left: -8px;
  margin-top: -8px;
  margin-bottom: 10px;
  padding: 5px;
  vertical-align: middle;
  color: #444444;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

#tooltip_content hr {
  margin: 10px;
}

.tooltipster-sidetip.tooltipster-light.tooltipster-light-customized .tooltipster-box {
  background: #fff;
  border-radius: 6px;
  box-shadow: 4px 4px 2px 0 rgba(0, 0, 0, 0.2);
}

.tooltipster-sidetip.tooltipster-light.tooltipster-light-customized .tooltipster-content {
  padding: 8px;
}

.teeth-active {
  border: solid 2px red;
}

.dropdown.bootstrap-select.show-tick button.btn-default {
  background-color: #fff;
}

.procedures-icons .finalize {
  text-decoration: none;
  color: #828482;
}

.procedures-icons .delete {
  text-decoration: none;
  color: #b9b8b8;
}

.procedures-icons .delete-lighter {
  opacity: 0.5;
}

.procedures-icons {
  text-align: right;
}

.procedures-icons span {
  margin-left: 15px;
  font-size: 20px;
}

.plan-list {
  color: #c1c1c1;
  font-weight: 600;
  display: block;
}

.procedures-delete {
  font-size: 19px;
  text-decoration: none !important;
  color: #929292;
}

.procedures-delete:hover {
  text-decoration: none !important;
  color: #929292;
}

.procedures-link {
  font-size: 15px;
  color: #4a89dc;
  padding-bottom: 0px;
  text-decoration: none !important;
}

ul.thumbnails.image_picker_selector {
  margin-top: 30px;
}

.col-lg-12.control-label.label-tooth {
  text-align: center;
}

ul.thumbnails.image_picker_selector li .thumbnail {
  padding: 2px;
  border: none;
}

ul.thumbnails.image_picker_selector li {
  margin: 0px 10px 12px 0px;
}

.thumbnail p {
  text-align: center;
}

.notification-listing {
  width: 100%;
}

.patient table thead:first-child tr:first-child {
  background: #fafafa;
  border: 2px solid #f1f1f1;
  font-weight: 600;
  font-size: 13px;
  color: gray;
}

.patient table tbody tr {
  padding: 1rem;
  border: 1px solid #f1f1f1;
  height: 80px;
}

.patient table tr td small {
  font-size: 75%;
}

.patient tr.inactive {
  background-color: rgba(249, 249, 249, 0.5098039216);
}

.font-inactive {
  color: #908e8e;
}

table thead tr td {
  padding: 10px;
}

table tbody tr td {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.teeth-number {
  display: block;
}

.img-content {
  display: block;
  min-height: 90px;
}

.tooltip-odontograma {
  cursor: pointer;
}

.content-scroll {
  height: calc(100vh - 240px);
  overflow: auto;
  background: #fff;
}

.no-margim {
  margin-left: -20px;
  margin-right: -11px;
  margin-bottom: -19px;
}

.btn-right-margin {
  margin-right: 0px;
  margin-top: 20px;
}

.no-procedure-table {
  text-align: center;
  color: grey;
}

.text-system.red {
  color: red;
}

.container.patient table.table {
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
}

.procedure-list {
  font-weight: 500;
}

.procedure-list.procedure-paid {
  color: #737272;
}

.procedure-list.procedure-paid .fa.fa-file-text-o {
  color: #2f8000;
  margin-right: 10px;
}

.paid-line {
  background: #f1f1f1;
  color: gray;
}

.debt-pending {
  background-color: #ffe6e6;
}

.procedure-pay {
  color: green;
  font-weight: 600;
}

.link-pay {
  font-weight: 600;
  text-decoration: none !important;
}

.edit-sidebar-link {
  text-align: center;
}

.edit-sidebar-link a {
  text-decoration: none;
}

.form-group.edit-debit label {
  margin-top: 20px;
}

.form-group.edit-debit label {
  display: block;
}

.form-group.edit-debit input {
  display: block;
  width: 100%;
  height: 39px;
  padding: 9px 12px;
  font-size: 13px;
  line-height: 1.49;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #dddddd;
  border-radius: 0px;
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}

.discount-type {
  float: left;
  display: inline-block;
  width: 8%;
  height: 38px;
  border: solid 1px #d2d2d2;
  font-size: 15px;
}

#discount {
  float: right;
  discount: inline-block;
  width: 90%;
}

.col-md-6.check-partial-payment label {
  display: inline-block;
  width: 10%;
}

.col-md-6.check-partial-payment input {
  display: inline-block;
  width: 80%;
}

.content-patient-quotes {
  margin-left: 10px;
  margin-right: 17px;
}

.evolution-procedure {
  display: block;
  color: grey;
  font-size: 13px;
  margin-top: 3px;
}

.edit-dentist-name {
  display: block;
  color: #a2a2a2;
  margin-top: 2px;
}

.odontogram .odontogram-top-teeth {
  border-bottom: dashed 1px #c5c5c5;
}

.panel-body.treatments .odontogram .odontogram-top-teeth .odontogram-tooth .odontogram-number-milk-tooth {
  color: red;
  display: block;
  margin-top: 5px;
  color: #afaeae;
  text-align: center;
}

.panel-body.treatments .odontogram .odontogram-bottom-teeth .odontogram-tooth .odontogram-number-milk-tooth {
  margin-bottom: 5px;
  display: block;
  margin-left: 0px;
  color: #afaeae;
  text-align: center;
}

.patients-search .panel.panel-patient-list a {
  text-decoration: none;
  color: inherit;
}

.patients-search .patient-name-left-block {
  margin-top: 10px;
  font-weight: 600;
}

.patients-search .initial.patient-search-block {
  width: 40px;
  height: 40px;
  font-size: 25px;
}

.patients-search .patient-list-left-block-detail {
  box-shadow: 0 2px 5px 0 #dee3e7;
}

.patients-search .patient-list-left-block {
  margin-top: 20px;
}

.patients-search .patient-list-left-side {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 5px;
  border-right: solid 1px #e6e6e6;
}

.patients-search ::placeholder {
  color: #aaaaaa;
  opacity: 1;
}

.patients-search .table-patient thead tr th {
  font-weight: 550;
  padding: 25px;
}

.patients-search .table-patient tbody tr td {
  padding: 20px;
}

.patients-search .table-patient tbody tr td label {
  color: gray;
}

.patients-search .initial-left-block {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.patients-search .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}

.patients-search .patient-info-right {
  color: #464646;
}

.patients-search .patient-info-right p span {
  color: gray;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: relative;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #808184;
  padding-top: 17px;
  font-size: 16px;
}

.form-group.has-search {
  margin-bottom: 17px;
  margin-top: -10px;
}

.form-group.has-search.clearfix input {
  font-size: 15px;
  height: 45px;
  border-radius: 3px;
}

.patients .table.result tr {
  height: 50px;
  font-size: 14px;
}

.patients-photo-image {
  width: 45px;
  height: 45px;
  border-radius: 9999px;
}

.patient-initial {
  border-radius: 50%;
  background-color: #f7f7f8;
  color: #92b7ea;
  font-size: 20px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  float: left;
}

.patients .table.result tr td span {
  margin-left: 13px;
}

.patients .table.result tr td a {
  text-decoration: none;
  font-size: 14px;
}

.patients .table td {
  padding: 16px 9px;
}

.search-footer {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px #d0d0d0;
  padding-top: 10px;
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 16px;
  color: gray;
}

.patient-footer-show-result {
  padding-bottom: 2px;
}

.patient-footer-pages a {
  text-decoration: none;
}

.patient-not-found {
  text-align: center;
  font-size: 16px;
  margin-top: 35px;
}

.patient-not-found i {
  font-size: 30px;
  color: gray;
}

.import-area .import-container {
  display: flex;
  margin-top: 20px;
}

.import-area .import-container .btn {
  margin-top: -7px;
  margin-left: 30px;
}

.import-area .feedback-container {
  margin-top: 20px;
}

.import-area .gui-input {
  border: solid 1px !important;
  border-radius: 2px !important;
}

.import-area .feedback-container li {
  font-size: 19.5px;
  color: red;
}
