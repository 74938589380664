.registration-sign-up-page .show-error {
  color: red;
  font-size: 12px;
  font-weight: 400;
}

.gradient-bg-register {
  background: linear-gradient(180deg, #F2F4F7 0%, #E1EBFF 100%);
}

@media screen and (max-width: 1024px) {
  .left-register-container {
    position: relative;
    right: 125px;
  }
}
.confirm-phone-number-container {
  transition: all 1s ease-out;
}

.js-professional-indication-select .option,
.js-professional-role-select .option {
  padding-left: 0px !important;
}

.js-professional-indication-select,
.js-professional-role-select {
  height: 44px !important;
  border-radius: 8px !important;
  border: 1px solid #D0D5DD !important;
}

.professional-role-select.nice-select .list {
  max-height: 210px;
}

.grecaptcha-badge {
  bottom: 90px !important;
}

.home-page .grecaptcha-badge {
  display: none !important;
  visibility: hidden !important;
}

.registration-sign-up-page {
  font-family: Inter, Helvetica, Arial, sans-serif;
}

.registration-form-section {
  min-height: 349px;
}

.professional-role-select {
  font-size: 14px;
  color: #666666;
  font-weight: 600;
  padding-left: 14px;
}

.nice-select.professional-role-select {
  padding-left: 0;
}
