.indication-chart-slice-instagram {
  fill: #fdba74;
}

.indication-bg-instagram {
  background-color: #fdba74;
}

.indication-chart-slice-facebook {
  fill: #67e8f9;
}

.indication-bg-facebook {
  background-color: #67e8f9;
}

.indication-chart-slice-google {
  fill: #fde047;
}

.indication-bg-google {
  background-color: #fde047;
}

.indication-chart-slice-family_indication {
  fill: #86efac;
}

.indication-bg-family_indication {
  background-color: #86efac;
}

.indication-chart-slice-friend_indication {
  fill: #059669;
}

.indication-bg-friend_indication {
  background-color: #059669;
}

.indication-chart-slice-dentist_indication {
  fill: #064e3b;
}

.indication-bg-dentist_indication {
  background-color: #064e3b;
}

.indication-chart-slice-newspaper {
  fill: #a5b4fc;
}

.indication-bg-newspaper {
  background-color: #a5b4fc;
}

.indication-chart-slice-outdoor {
  fill: #ef4444;
}

.indication-bg-outdoor {
  background-color: #ef4444;
}

.indication-chart-slice-radio {
  fill: #22c55e;
}

.indication-bg-radio {
  background-color: #22c55e;
}

.indication-chart-slice-tv {
  fill: #eab308;
}

.indication-bg-tv {
  background-color: #eab308;
}

.indication-chart-slice-dental_plan {
  fill: #d8b4fe;
}

.indication-bg-dental_plan {
  background-color: #d8b4fe;
}

.indication-chart-slice-website {
  fill: #06b6d4;
}

.indication-bg-website {
  background-color: #06b6d4;
}

.indication-chart-slice-other {
  fill: #78716c;
}

.indication-bg-other {
  background-color: #78716c;
}

.indication-list--active .indication-item {
  opacity: 0.1;
}

.indication-list--active .indication-item--active {
  opacity: 1;
}

.indication-charts .ct-chart-pie--active .ct-slice-pie {
  opacity: 0.1;
}
.indication-charts .ct-chart-pie--active .ct-slice-pie--actice {
  opacity: 1;
}
.indication-charts .ct-label--actice {
  font-weight: 600;
  font-size: 19px;
}
.indication-charts .pie-chart * {
  width: 100% !important;
}
