.documents .btn-add {
  text-align: center;
  margin-top: 25px;
  padding-bottom: 25px;
  border-bottom: dashed 1px grey;
}

.documents .table {
  width: 600px;
  margin: 0 auto;
  margin-top: 28px;
}

.btn-prescription-save .dropdown {
  float: right;
}

.btn-prescription-save {
  height: 40px;
  padding-top: 20px;
  padding-bottom: 35px;
  border-bottom: dashed 1px #DDDDDD;
  padding-bottom: 57px;
  margin-bottom: 20px;
}

#saved-prescriptions {
  border: solid 1px #cecece;
  padding: 6px;
  background: #f7f7f7;
  color: #484747;
  width: 125px;
  display: block;
  text-decoration: none;
}

.prescription-detail {
  margin-top: 20px;
}

.prescription-detail .top-prescription label {
  font-size: 13px;
  margin-right: 5px;
  color: #3c3c3c;
  width: 160px;
  font-weight: 600;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .prescription-detail .top-prescription label {
    text-align: left;
  }
}

.prescription-detail input {
  padding-left: 7px;
  font-size: 13px;
}

.prescription-detail .content-block {
  height: 35px;
}
@media screen and (max-width: 1024px) {
  .prescription-detail .content-block {
    height: auto;
  }
}

.prescription-detail .content-block span {
  font-size: 14px;
  color: #90949c;
  margin-left: 35px;
}
@media screen and (max-width: 768px) {
  .prescription-detail .content-block span {
    margin-left: 0;
  }
}

.prescription-detail .content-block.first .col-md-10 span {
  margin-left: 0px;
}

.prescription-detail .external,
.prescription-detail .internal {
  line-height: 1.6;
}

.prescription-detail .internal {
  margin-left: 20px;
}

.prescription-detail .content-block.first label {
  line-height: 3;
}

.prescription-detail .date-prescription.hasDatepicker {
  margin-left: 30px;
  padding-left: 6px !important;
  height: 30px;
  border-radius: 2px;
  border: solid 1px;
  border-color: #cccccc;
}
@media screen and (max-width: 768px) {
  .prescription-detail .date-prescription.hasDatepicker {
    margin-left: 0;
  }
}

.prescription-detail hr {
  border-top: 1px dashed #DDD;
  margin-top: 15px;
}

.prescription-detail .tt-input {
  width: 100%;
  position: relative;
  vertical-align: top;
  background-color: transparent;
  border: solid 1px #cccccc;
  padding: 5px;
  padding-left: 10px;
  font-size: 15px;
}

.measure-select {
  width: 100%;
}

.prescription-detail .dosage {
  width: 100%;
}

.prescription-block .content-block {
  margin-bottom: 20px;
}

.prescription-block .content-block.final {
  margin-bottom: 35px;
  margin-top: 40px;
}

.prescription-detail .bs-component {
  margin-left: 14px;
}

.prescription-block .content-block.check {
  margin-bottom: 5px;
}

.content-block.final .col-md-3 label {
  line-height: 3.3;
}

.prescription-block .prescription-needed-label {
  line-height: 2;
}

.panel-footer.text-right.prescription {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: -19px;
  margin-right: -20px;
  margin-left: -20px;
  padding-right: 20px;
  margin-top: 30px;
}

.prescription-block label,
.prescription-detail label {
  line-height: 3;
}

.content-block.first label {
  line-height: 0;
}

.content-block.first .col-md-10 span {
  font-size: 16px;
  font-weight: 600;
  color: #41536e;
}

.content-block.first {
  margin-bottom: 27px;
  margin-top: 20px;
}

.drug .content-block.first {
  margin-bottom: 10px;
}

.drug .content-block {
  margin-bottom: 20px;
}

.remove-drug {
  float: right;
  margin-right: 17px;
}

.hr-drug {
  margin-top: 40px !important;
}

@media (min-width: 768px) {
  .drug textarea.dosage {
    height: 7.5rem;
  }
}

.date-prescription {
  padding-left: 0px !important;
  color: #90949c;
}

label.internal,
label.external {
  margin-right: 20px;
}

.remove-drug {
  font-size: 16px;
}

.saved-prescriptions-list {
  white-space: nowrap;
}

.dropdown-menu.saved-prescriptions-list .saved-prescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
}

.dropdown-menu.saved-prescriptions-list li {
  max-width: auto;
}

.prescription-detail .tt-hint {
  width: 100%;
  padding: 5px;
  padding-left: 10px;
  font-size: 15px;
}

.prescription-typeahead {
  cursor: pointer;
  padding-left: 18px;
  color: grey;
  border-top: dashed 1px #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}

.dropdown-menu.dropdown-menu-right.saved-prescriptions-list {
  min-width: 255px;
}

.save-prescription-btn {
  margin-right: 20px;
  text-decoration: none !important;
  font-weight: 600;
}

.panel-footer.text-right.prescription .btn.btn-hover.btn-default.btn-block {
  width: 80px;
  float: left;
  margin-left: 20px;
  height: 38px;
}

.btn-back-top-prescription {
  float: left;
  margin-top: 9px;
  text-decoration: none;
}

.btn-back-top-prescription a {
  text-decoration: none;
}

.col-md-2.trash {
  height: 40px;
  line-height: 2.4;
}

.dropdown-menu.saved-prescriptions-list .list-empty {
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px;
  color: #7e7e80;
  height: 35px;
}

.dropdown-menu.saved-prescriptions-list .list-empty i {
  font-size: 16px;
}

.col-md-3.prescription-modal label {
  line-height: 3;
}

.modal-body.row-modal-prescription {
  height: 115px;
  padding-top: 40px;
}
@media screen and (max-width: 769px) {
  .modal-body.row-modal-prescription {
    margin-bottom: 16px;
    height: auto;
  }
}

.container.patient.documents .row.btn-alternatives {
  margin-top: 57px;
  margin-bottom: 57px;
}

.container.patient.documents .row.btn-alternatives .col-md-6.col-sm-6.left {
  text-align: right;
}

.container.patient.documents .col-md-9.patient-right-side {
  padding-right: 20px;
}

.container.patient.documents .row.btn-alternatives .col-md-6.col-sm-6.right {
  text-align: left;
}

.container.patient.documents hr {
  border-top: dashed 1px #d2d2d2;
  margin-bottom: 57px;
}

.delete-document {
  color: #cecece;
  margin-left: 30px;
}

.delete-document:hover {
  color: #cecece;
}

.print-document {
  text-align: center;
}

.print-document a {
  font-size: 18px;
}

.documents-container {
  display: flex;
  justify-content: center;
  margin: 30px;
}
@media screen and (max-width: 769px) {
  .documents-container {
    flex-direction: column;
    align-items: center;
  }
  .documents-container > * {
    width: 100%;
    margin-top: 12px;
  }
  .documents-container > * > * {
    width: 100%;
  }
}

.documents-container .btn-2 {
  margin-left: 20px;
  margin-right: 20px;
}

.create-new-document-button-container {
  display: flex;
  justify-content: center;
}

.checkbox-sigantures-container {
  display: flex;
  justify-content: center;
}

.checkbox-sigantures-container label {
  margin-left: 10px;
  margin-right: 30px;
}

#custom-document-footer .btn-secondary {
  margin-right: 10px;
}

#custom-document-container #custom_document_title {
  font-size: 15px;
}

#custom-document-container #custom_document_body_text {
  font-size: 15px;
  border-radius: 5px;
}

#custom-documents-helper-link-container {
  display: flex;
  justify-content: center;
  padding: 30px;
}

#optional-words-container {
  display: flex;
  justify-content: center;
  line-height: 25px;
}

#optional-words-container p {
  font-size: 10px;
  padding-bottom: 30px;
  padding-left: 85px;
}

#optional-words-container span {
  background-color: #f0f0f0;
  font-weight: 600;
  color: #666666;
  border-radius: 5px;
  padding: 4px;
}

.btn-custom-documents-save .dropdown {
  float: right;
}

.btn-custom-documents-save {
  height: 40px;
  padding-top: 20px;
  padding-bottom: 35px;
  border-bottom: dashed 1px #DDDDDD;
  padding-bottom: 57px;
  margin-bottom: 20px;
}

.btn-back-top-custom-documents {
  float: left;
  margin-top: 9px;
  text-decoration: none;
}

.btn-back-top-custom-documents a {
  text-decoration: none;
}

#saved-custom-documents {
  border: solid 1px #cecece;
  padding: 6px;
  background: #f7f7f7;
  color: #484747;
  width: 125px;
  display: block;
  text-decoration: none;
}

.saved-custom-documents-list {
  max-height: 50vh;
  overflow: auto;
  white-space: nowrap;
}

.dropdown-menu.saved-custom-documents-list .saved-custom-document {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
}

.dropdown-menu.saved-custom-documents-list li {
  max-width: auto;
}

.dropdown-menu.dropdown-menu-right.saved-custom-documents-list {
  min-width: 255px;
}
@media screen and (max-width: 769px) {
  .dropdown-menu.dropdown-menu-right.saved-custom-documents-list {
    max-width: 80vw;
  }
}

.dropdown-menu.saved-custom-documents-list .list-empty {
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px;
  color: #7e7e80;
  height: 35px;
}

.dropdown-menu.saved-custom-documents-list .list-empty i {
  font-size: 16px;
}

.panel-footer.text-right.custom-documents {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: -19px;
  margin-right: -20px;
  margin-left: -20px;
  padding-right: 20px;
  margin-top: 30px;
}

.panel-footer.text-right.custom-documents .btn.btn-hover.btn-default.btn-block {
  width: 80px;
  float: left;
  margin-left: 20px;
  height: 38px;
}

#list-custom-documents-title {
  width: 55%;
}

#custom-document-list-item-container {
  padding: 5px;
  display: grid;
  grid-template-columns: auto 20px;
  gap: 5px;
}

#label-save-custom-document {
  white-space: nowrap;
}

#custom-document-modal {
  display: block;
  position: absolute;
  z-index: 1;
  top: 40%;
  left: 40%;
}

#custom-document-modal input {
  width: 400px;
}

#generate-custom-document-pdf-button {
  margin-left: 15px;
}

.custom-document-select-tag {
  height: 38px;
  min-width: 100%;
}

.custom-documents-dropdown-title:hover {
  background-color: white !important;
}
