.internal-chat{
  @apply tw-bottom-2 tw-flex-col tw-shadow-2xl tw-rounded-lg;
  background-color: white;
  display: flex;
  left: 70px;
  max-height: 100vh;
  max-height: calc(100vh - 10px);
  position: fixed;
  z-index: 1030;
  max-width: 100%;
}

.internal-chat--open{
  height: 470px;
  width: 280px;
}

.internal-chat--close{
  height: 52px;
  width: 200px;
}

@media screen and (max-width: 767px) {
  .internal-chat{
    left: 16px;
  }

  .internal-chat--open{
    height: 600px;
    width: 280px;
  }
}
