.debt-payment-method {
  column-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  row-gap: 8px;
}

@media (max-width: 768px) {
  .debt-payment-method__item {
    width: calc(50% - 8px);
  }
}

.debt-payment-method__input {
  display: none;
}

.debt-payment-method__label {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #344054;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  padding: 10px 16px;
  position: relative;
}
@media (max-width: 768px) {
  .debt-payment-method__label {
    text-align: center;
    width: 100%;
  }
}

.debt-payment-method__label:before {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 20px;
  left: 12px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 30px;
}

.debt-payment-method__input:checked + .debt-payment-method__label {
  background-color: #4299e1;
  border-color: #4299e1;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.pay-method-list-icons {
  width: 180px;
  float: left;
  height: 50px;
}
@media (max-width: 768px) {
  .pay-method-list-icons {
    width: 50%;
  }
}

.pay-method-list-icons input {
  font-size: 16px;
}

.label-pay-modal {
  color: #6f6e6e;
  font-size: 14px;
}
