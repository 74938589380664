.odontogram-switcher-link:hover,
.odontogram-switcher-link:focus {
  text-decoration: none;
}

.odontogram {
  margin-bottom: 20px;
  margin-top: 10px;
  line-height: 40px;
}

.odontogram > div {
  margin: 0 auto;
  width: 509px;
}

.odontogram-deciduous {
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.odontogram-deciduous > div {
  width: 322px;
}

@media screen and (max-width: 769px) {
  .odontogram {
    overflow: auto;
  }
}
.odontogram-tooth {
  display: inline-block;
}

.odontogram-number strong {
  font-weight: bold !important;
}

.odontogram-top-teeth {
  border-bottom: dashed 1px #c5c5c5;
}

.odontogram-top-teeth .odontogram-number {
  margin-top: 0px;
  margin-bottom: 0px;
}

.odontogram-bottom-teeth .odontogram-number {
  margin-top: -5px;
  margin-bottom: -5px;
}

.odontogram-bottom-teeth {
  margin-top: 0;
}

.odontogram-tooth-cross {
  position: absolute;
  bottom: 9px;
  left: 6px;
  font-size: 30px;
  color: #F44336;
}

.odontogram-tooth-cross {
  position: absolute;
  bottom: 12px;
  left: 6px;
  font-size: 28px;
}

.odontogram-bottom-teeth .odontogram-tooth-cross {
  bottom: inherit;
  top: 7px;
}

.odontogram-tooth-image-18 .odontogram-tooth-cross {
  left: 2px;
}

.odontogram-tooth-image-16 .odontogram-tooth-cross {
  left: 8px;
}

.odontogram-tooth-image-15 .odontogram-tooth-cross {
  left: 4px;
}

.odontogram-tooth-image-14 .odontogram-tooth-cross {
  left: 4px;
}

.odontogram-tooth-image-12 .odontogram-tooth-cross {
  left: 4px;
}

.odontogram-tooth-image-22 .odontogram-tooth-cross {
  left: 3px;
}

.odontogram-tooth-image-27 .odontogram-tooth-cross {
  left: 8px;
}

.odontogram-tooth-image-28 .odontogram-tooth-cross {
  left: 8px;
}

.odontogram-tooth-image-48 .odontogram-tooth-cross {
  left: 9px;
}

.odontogram-tooth-image-42 .odontogram-tooth-cross {
  left: 3px;
}

.odontogram-tooth-image-41 .odontogram-tooth-cross {
  left: 3px;
}

.odontogram-tooth-image-31 .odontogram-tooth-cross {
  left: 3px;
}

.odontogram-tooth-image-32 .odontogram-tooth-cross {
  left: 3px;
}

.odontogram-tooth-image-37 .odontogram-tooth-cross {
  left: 11px;
}

.odontogram-tooth-image-38 .odontogram-tooth-cross {
  left: 8px;
}

.odontogram-tooth-on-process {
  background-position-y: -334px !important;
}

.odontogram-bottom-teeth .odontogram-tooth-on-process {
  background-position-y: -420px !important;
}

.odontogram-tooth-done {
  background-position-y: -167px !important;
}

.odontogram-bottom-teeth .odontogram-tooth-done {
  background-position-y: -253px !important;
}

.odontogram-tooth-image {
  position: relative;
  background: url("odontogram-sprite.png");
  background-repeat: no-repeat;
  height: 85px;
  cursor: pointer;
}

.odontogram-tooth-image-18 {
  width: 26px;
  background-position: 0 0;
  margin-left: 7px;
}

.odontogram-tooth-image-17,
.odontogram-tooth-image-55 {
  width: 31px;
  background-position: -27px 0;
}

.odontogram-tooth-image-16,
.odontogram-tooth-image-54 {
  width: 31px;
  background-position: -59px 0;
}

.odontogram-tooth-image-15 {
  width: 24px;
  background-position: -91px 0;
}

.odontogram-tooth-image-14 {
  width: 26px;
  background-position: -117px 0;
}

.odontogram-tooth-image-13,
.odontogram-tooth-image-53 {
  width: 29px;
  background-position: -145px 0;
}

.odontogram-tooth-image-12,
.odontogram-tooth-image-52 {
  width: 22px;
  background-position: -177px 0;
}

.odontogram-tooth-image-11,
.odontogram-tooth-image-51 {
  width: 29px;
  background-position: -203px 0;
}

.odontogram-tooth-image-21,
.odontogram-tooth-image-61 {
  width: 29px;
  background-position: -239px 0;
  margin-left: 6px;
}

.odontogram-tooth-image-22,
.odontogram-tooth-image-62 {
  width: 21px;
  background-position: -272px 0;
}

.odontogram-tooth-image-23,
.odontogram-tooth-image-63 {
  width: 28px;
  background-position: -297px 0;
}

.odontogram-tooth-image-24 {
  width: 26px;
  background-position: -328px 0;
}

.odontogram-tooth-image-25 {
  width: 24px;
  background-position: -356px 0;
}

.odontogram-tooth-image-26,
.odontogram-tooth-image-64 {
  width: 31px;
  background-position: -381px 0;
}

.odontogram-tooth-image-27,
.odontogram-tooth-image-65 {
  width: 31px;
  background-position: -413px 0;
}

.odontogram-tooth-image-28 {
  width: 26px;
  background-position: -445px 0;
}

.odontogram-tooth-image-48 {
  width: 35px;
  height: 57px;
  background-position: 0 -86px;
}

.odontogram-tooth-image-47,
.odontogram-tooth-image-85 {
  width: 35px;
  height: 61px;
  background-position: -35px -86px;
}

.odontogram-tooth-image-46,
.odontogram-tooth-image-84 {
  width: 34px;
  height: 68px;
  background-position: -72px -86px;
}

.odontogram-tooth-image-45 {
  width: 27px;
  height: 72px;
  background-position: -107px -86px;
}

.odontogram-tooth-image-44 {
  width: 25px;
  height: 73px;
  background-position: -136px -86px;
}

.odontogram-tooth-image-43,
.odontogram-tooth-image-83 {
  width: 26px;
  height: 81px;
  background-position: -167px -86px;
}

.odontogram-tooth-image-42,
.odontogram-tooth-image-82 {
  width: 21px;
  height: 79px;
  background-position: -198px -86px;
}

.odontogram-tooth-image-41,
.odontogram-tooth-image-81 {
  width: 21px;
  height: 71px;
  background-position: -223px -86px;
}

.odontogram-tooth-image-31,
.odontogram-tooth-image-71 {
  width: 21px;
  height: 71px;
  background-position: -253px -86px;
  margin-left: 6px;
}

.odontogram-tooth-image-32,
.odontogram-tooth-image-72 {
  width: 21px;
  height: 79px;
  background-position: -278px -86px;
}

.odontogram-tooth-image-33,
.odontogram-tooth-image-73 {
  width: 26px;
  height: 81px;
  background-position: -304px -86px;
}

.odontogram-tooth-image-34 {
  width: 26px;
  height: 72px;
  background-position: -335px -86px;
}

.odontogram-tooth-image-35 {
  width: 27px;
  height: 72px;
  background-position: -363px -86px;
}

.odontogram-tooth-image-36,
.odontogram-tooth-image-74 {
  width: 34px;
  height: 69px;
  background-position: -391px -86px;
}

.odontogram-tooth-image-37,
.odontogram-tooth-image-75 {
  width: 35px;
  height: 62px;
  background-position: -425px -86px;
}

.odontogram-tooth-image-38 {
  width: 35px;
  height: 54px;
  background-position: -461px -86px;
}

.odontogram-number {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.odontogram-selecter input, .odontogram-selecter label {
  cursor: pointer;
  font-weight: normal;
}

.odontogram-selecter-input {
  margin-right: 5px !important;
}

.odontogram-selecter-input:last-of-type {
  margin-left: 30px;
}
