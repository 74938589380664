.quotes .btn-add {
  text-align: center;
}

.panel.quotes-list {
  margin-left: 12px;
  margin-right: 13px;
  padding-top: 25px;
}

.form-group.top-quotes {
  padding-top: 20px;
  padding-bottom: 20px;
}

.fields-list .form-horizontal {
  background: #f5f5f5;
  padding: 10px;
  padding-bottom: 20px;
  padding-top: 15px;
  border: solid 1px #e0e0e0;
  margin-bottom: 20px;
}

.col-md-12.link-add-procedure {
  text-align: center;
}

.col-md-12.link-add-procedure a {
  text-decoration: none !important;
  font-weight: 550;
}

.close.block-quotes {
  margin-top: -10px;
  margin-bottom: 10px;
  position: absolute;
  /* background: red; */
  width: 30px;
  right: 0px;
  z-index: 100;
}

.line-quotes {
  border-bottom: dashed 1px #ced0d4;
  margin-bottom: 25px;
  margin-top: 25px;
  margin-left: -20px;
  margin-right: -10px;
}

.form-control.discount-type-select {
  width: 15%;
  display: inline-block;
}
@media screen and (max-width: 769px) {
  .form-control.discount-type-select {
    width: 25%;
  }
}

.form-control.discount-input {
  float: right;
  width: 80%;
}
@media screen and (max-width: 769px) {
  .form-control.discount-input {
    width: 70%;
  }
}

.discount-label {
  display: block;
}

.input-block {
  margin-bottom: 20px;
}

.control-label.partial-label {
  margin-bottom: 0px;
}

.block.mt15.switch.switch-primary.partial {
  margin-top: 3px !important;
}

.footer-quotes {
  padding-top: 20px;
  padding-bottom: 20px;
  background: whitesmoke;
  margin-left: -31px;
  margin-right: -22px;
  margin-bottom: -20px;
  margin-top: 20px;
  padding-left: 30px;
  border-top: solid 1px #e2e2e2;
  padding-right: 22px;
}

.partial-block-detail.clearfix {
  border: solid 1px #dedede;
  padding-top: 15px;
  padding-bottom: 20px;
  margin-top: 20px;
  border-radius: 5px;
}

.block-total {
  background: #f5f5f5;
  padding-top: 24px;
  padding-bottom: 22px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 3px;
  margin-top: 29px;
  line-height: 27px;
  border: solid 1px #d6d6d6;
}

.title-total {
  font-weight: 600;
  font-size: 16px;
  width: 130px;
  display: inline-block;
}

.title-total.previus {
  font-weight: 500;
}

.discount-label {
  margin-bottom: 10px;
}

.value-total {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
}

.content-patient-quotes .content-header {
  margin-bottom: 0px;
}

.content-header .lead {
  margin-bottom: 0px;
}

.body-quotes {
  font-family: Helvetica, sans-serif;
  font-size: 15px;
  color: #636363;
}

.body-quotes .panel-body,
.panel-footer.br-t.p12 {
  text-align: center;
}

.form-group.entry {
  border-bottom: dashed 1px #b3b3b3;
  padding-bottom: 35px;
}

.content-patient-quotes h2 {
  font-weight: 300;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.content-patient-quotes h2.line {
  border-top: dashed 1px #b3b3b3;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.total-quotes-approve {
  margin-bottom: 20px;
}

.payment-partial-detail.clearfix {
  margin-top: 20px;
}

.content-partial-number {
  margin-bottom: 20px;
}

.content-partial-number.clearfix input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
}

.input-off-partial {
  border: none !important;
  font-size: 16px;
  color: #636363;
  padding-left: 0px !important;
  background-color: #f5f5f5;
  font-weight: 600;
}

.footer-partial-pay.text-right {
  background: #ececec;
  margin-right: -28px;
  margin-left: -30px;
  margin-bottom: -20px;
  padding-top: 0px;
  padding-bottom: 20px;
  padding-right: 27px;
  border-top: solid 1px #d4d4d4;
}

.block-partial-detail {
  background: #f5f5f5;
  margin-bottom: 5px;
  padding-top: 20px;
  border: solid 1px #e6e6e6;
}

.lead.top-quotes {
  font-size: 15px;
  margin-top: 20px;
}

.body-quotes input {
  font-size: 15px;
}

.patient table thead:first-child tr:first-child {
  background: #fafafa;
  border: 1px solid #e4e4e4;
  font-weight: 600;
  font-size: 13px;
  color: gray;
}

.print-icon {
  font-size: 18px;
  text-align: right;
  text-decoration: none !important;
  margin-right: 10px;
}

.print-icon.dark {
  color: grey;
}

.print-icon-lighter {
  opacity: 0.4;
}

.icon-col {
  text-align: left;
}
@media screen and (min-width: 768px) {
  .icon-col {
    padding: 0px !important;
  }
}

.approved-quotes {
  background-color: #e1ffe5;
}

span.quotes-aproved-mark {
  padding: 3px;
  line-height: -26px;
  margin-right: 5px;
  border: dashed 1px #727a73;
}

.footer-partial-pay.text-right .btn.btn-default {
  float: left;
  margin-top: 20px;
  margin-left: 27px;
}

.dental-plan small {
  margin-left: 10px;
  color: grey;
  font-style: italic;
}

.dropdown.bootstrap-select.show-tick {
  width: 100% !important;
}

.btn-group.print-button .btn.btn-default.dropdown-toggle {
  border: none;
  background-color: transparent;
  padding: 0px;
  font-size: 17px;
}

#add-procedure-container a {
  background-color: #f0f0f0;
  display: inline-block;
  padding: 6px;
  border-radius: 5px;
  word-wrap: break-word;
}

.quote-amount-container-span {
  white-space: nowrap;
}

.quote-amount-currency-span {
  font-size: 14px;
}

.quote-amount-span {
  white-space: nowrap;
}

.quote-details {
  display: none !important;
  opacity: 0;
}

.quote-details--visible {
  display: table-row !important;
  opacity: 1;
}

@media only screen and (max-width: 769px) {
  .quotes-list {
    overflow-x: auto;
  }
  .quote-details table.table--no-stack {
    display: table !important;
  }
  .quote-details table.table--no-stack thead tr {
    position: relative;
    top: 0;
    left: 0;
  }
  .quote-details table.table--no-stack tr {
    display: table-row !important;
  }
  .quote-details table.table--no-stack tbody {
    display: table-row-group !important;
  }
  .quote-details table.table--no-stack thead {
    display: table-header-group !important;
  }
  .quote-details table.table--no-stack td, .quote-details table.table--no-stack th {
    display: table-cell !important;
  }
  .quote-details .quote-details__debts td:nth-of-type(1):before {
    content: "Valor";
  }
  .quote-details .quote-details__debts td:nth-of-type(2):before {
    content: "Data vencimento";
  }
  .quote-details .quote-details__debts td:nth-of-type(3):before {
    content: "Status";
  }
  .quote-details .quote-details__debts td:nth-of-type(4) {
    margin-top: 4px;
  }
}
