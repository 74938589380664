.professional-infos-tab {
  -webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%);
}

.establishment-infos-tab {
  margin-left: -17px !important;
  -webkit-clip-path: polygon(
    75% 0%,
    100% 50%,
    75% 100%,
    0% 100%,
    25% 50%,
    0% 0%
  );
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 100%, 7% 50%, 0% 0%);
}

.professional-infos-tab.active,
.establishment-infos-tab.active {
  @apply !tw-bg-ublue-100 tw-font-medium !tw-text-ugray-600;
}

.register-page .sj-social-star-masked-overflow {
  @apply !tw-min-h-[100vh];
}

@media screen and (max-width: 960px) {
  .register-page .avatar-container {
    @apply !tw-hidden;
  }
  .register-page .bubble .review-bubble:before {
    @apply !tw-hidden;
  }
}
.register-page .bubble-background {
  display: none;
}

.register-page .bubble .avatar {
  border-width: 1px !important;
  border-color: #d0d5dd !important;
}

.register-page .bubble .review-bubble {
  border-width: 1px !important;
  border-color: #d0d5dd !important;
}



.register-page .bubble .content {
  @apply sm:tw-text-base tw-text-sm tw-text-ugray-700;
}

.register--form__container {
  @apply lg:tw-flex tw-flex-col tw-gap-4 tw-mx-auto tw-my-auto
         tw-min-h-[44.375rem] tw-max-w-[37.5rem] tw-py-6
         md:tw-px-10 sm:tw-px-6 tw-px-10;
}

@media screen and (max-width: 380px) {
  .register--form__container {
    @apply tw-px-4 tw-w-full;
  }
}
