.professional-switcher {
  padding-top: 12px;
  padding-bottom: 12px;
}

.professional-switcher__item {
  align-items: center;
  border-radius: 6px;
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  line-height: 20px;
  width: 100%;
}
.professional-switcher__item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 16px);
}
.professional-switcher--expand-text .professional-switcher__item-text {
  overflow: visible;
  white-space: break-spaces;
}
.professional-switcher__item-link {
  align-items: center;
  display: flex;
  padding: 12px 8px;
  padding-right: 4px;
  width: 100%;
}
.professional-switcher__item:hover {
  background: #F9FAFB;
  color: #101828;
}

.professional-switcher__item_active {
  background-color: #F9FAFB;
  color: #101828;
  font-weight: 600;
}

.professional-switcher__item_active .professional-switcher__item-link {
  cursor: default;
}

.professional-switcher--disabled .professional-switcher__item-link {
  cursor: default;
}
