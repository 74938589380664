.navbar .nav > li > a {
  color: #666;
  font-size: 13px;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 45px;
  max-height: 59px;
}
@media (max-width: 768px) {
  .navbar .nav > li > a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 768px) {
  .navbar .nav > li > a {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

@media (min-width: 860px) {
  .navbar-branding {
    margin-right: 82px;
  }
}

.navbar-brand-image {
  margin-top: -9px;
  width: 70px;
}
@media (min-width: 400px) {
  .navbar-brand-image {
    width: 100px;
  }
}

.navbar .nav > li.dropdown.menu-merge.open .dropdown-menu,
.navbar .nav > li.dropdown.disable-arrow.open .dropdown-menu {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .navbar.navbar-fixed-top.navbar-shadow,
  .black-friday__header.navbar.navbar-fixed-top.navbar-shadow {
    height: auto;
  }
}
.black-friday.navbar .nav > li.open > a, .black-friday.navbar .nav > li:hover > a, .black-friday.navbar .nav > li:focus > a, .black-friday.navbar .nav > li.active > a, .black-friday.navbar .nav > li > a:hover, .black-friday.navbar .nav > li > a:focus {
  background-color: transparent;
}

.black-friday .nav.navbar-nav.navbar-left .active a {
  color: #fff;
}

.black-friday .nav.navbar-nav.navbar-left a:hover, .black-friday .nav.navbar-nav.navbar-left a:focus {
  color: #fff;
}
