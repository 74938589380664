.uploads-preview {
  width: 50% !important;
  margin: auto;
}

.uploads-preview__small .uploads-preview {
  width: 200px !important;
}

.file-upload-content {
  text-align: center;
  border-bottom: solid 1px #e2e2e2;
  padding-bottom: 25px;
}

.file-upload-content label {
  text-align: right;
}

.upload-btn-final {
  display: block;
  margin-top: 20px;
}

.upload-btn-final.clearfix .button.btn-system,
.btn-edit-upload.btn-system {
  padding: 7px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  font-size: 14px;
  float: right;
}

.uploaded-files .img-responsive {
  display: inline-block;
  height: 250px;
  background: #fbfbfb;
  text-align: center;
  margin-top: 25px;
  padding: 10px;
  vertical-align: midle;
  margin-right: 25px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  position: relative;
}

.uploaded-files .img-responsive span {
  position: absolute;
  bottom: 0;
  display: block;
  margin: 0 auto;
  width: 250px;
  color: #ffffff;
  margin-left: -10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.39);
  font-family: Roboto, Helvetica, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-title.clearfix {
  margin-top: 10px;
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
}

.upload-title.disabled.clearfix {
  color: grey;
  font-weight: 300;
  margin-top: 40px;
  text-align: center;
}

.cancel-upload-btn {
  float: left;
  padding-left: 13px;
  padding-right: 13px;
  color: #666666;
  background-color: #f0f0f0;
  border-color: rgba(0, 0, 0, 0.1);
  height: 35px;
}

.img-actions {
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border: solid 1px #cccbcb;
  border-bottom: 0px;
  border-bottom: solid 1px #e4e4e4;
}

.img-actions a {
  margin-right: 30px;
  color: #656669;
  /* font-weight: 600; */
  font-size: 13px;
  text-decoration: none;
}

.upload-notes {
  margin-bottom: 20px;
  margin-top: 20px;
}

.upload-image-name {
  font-weight: 600;
  font-size: 16px;
}

.container.upload-edit-area {
  padding-top: 30px;
}

.col-md-2.preview-img-upload img {
  display: block;
  width: 100% \9 ;
  max-width: 100%;
  height: auto;
}

.col-md-4.preview-img-upload img.img-responsive {
  margin: 0 auto;
}

.col-md-3.img-responsive {
  margin: 13px auto;
  margin-left: 43px;
  overflow: hidden;
}

.col-md-3.img-responsive a {
  height: 200px;
  display: block;
}

.col-md-3.img-responsive a img {
  width: 100%;
}

.uppy-Dashboard-close:focus {
  outline: none;
}

.uppy-Dashboard--modal, .uppy-Dashboard--modal .uppy-Dashboard-overlay {
  z-index: 1031;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1032;
}

.blue-background-class a {
  background-color: #4899e1 !important;
  opacity: 0.7;
}
.blue-background-class img {
  display: none;
}

.tui-image-editor-container .tui-image-editor-help-menu.right {
  height: auto !important;
  z-index: 10;
}

.tui-image-editor-container .tui-image-editor-header-logo,
.tie-btn-deleteAll,
.tie-btn-delete,
.tie-btn-redo,
.tie-btn-history,
.tie-btn-reset + .tui-image-editor-item,
.tie-btn-filter,
.tie-btn-resize,
.tie-btn-flip,
.tie-btn-mask,
.tui-image-editor-header,
.tui-image-editor-button.triangle,
[data-icontype=icon-arrow-3],
[data-icontype=icon-star],
[data-icontype=icon-star-2],
[data-icontype=icon-polygon],
[data-icontype=icon-location],
[data-icontype=icon-bubble],
[data-icontype=icon-heart],
.tie-btn-hand + .tui-image-editor-item,
.tui-image-editor-controls-logo,
.tui-image-editor-controls-buttons,
.tui-image-editor-menu-icon .tie-icon-add-button:nth-child(3),
.tui-image-editor-menu-icon > ul > li:nth-child(2) {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .tui-image-editor-menu, .tui-image-editor-help-menu {
    display: none !important;
  }
}
.tui-image-editor-canvas-container {
  z-index: 10;
}

.upload-editor {
  height: 540px;
}

@media screen and (min-width: 768px) {
  .upload-editor {
    height: 940px;
  }
}
