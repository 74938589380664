.patient-resp a {
  color: #5e5d5d !important;
  letter-spacing: 0 !important;
}

.remaining-message-counter {
  width: 100%;
}

@media (min-width: 992px) {
  .remaining-message-counter {
    width: 555px;
  }
}
.messages-menu ul {
  list-style: none;
}

.messages-menu ul {
  list-style: none;
  margin-left: -40px;
}

.messages-menu ul li {
  line-height: 60px;
  padding-left: 15px;
}

.messages-menu ul li.active {
  background: #f7f7f7;
  border-left: solid 5px #66708d;
  padding-left: 9px;
}

.messages-menu ul li.active a {
  font-weight: 600;
}

.messages-menu ul li a {
  color: #556080;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}

.messages-menu ul li a small {
  font-size: 9px;
  color: red;
  line-height: 52px;
  position: absolute;
  margin-left: 5px;
}

.messages-menu ul li a span {
  margin-right: 3px;
}

.messages-area .notification-listing {
  line-height: normal;
  border-radius: 3px;
  border-style: hidden;
  border-collapse: collapse;
  margin: 0 auto;
  margin-top: 20px;
  border-spacing: 0;
  table-layout: fixed;
}

.messages-area table tr {
  padding: 1rem;
  border-top: 2px solid #f1f1f1;
}

.messages-area table thead tr td {
  padding: 10px;
}

.messages-area table tbody tr td {
  padding: 10px;
}

.messages-area .icon-type {
  text-align: center;
  font-size: 20px;
  color: gray;
}

.messages-area .notification-listing .type {
  width: 5%;
}

.messages-area .notification-listing .description {
  width: 65%;
}

.messages-area .notification-listing .date {
  width: 30%;
}

.messages-area .notification-listing .detail {
  width: 10%;
}

.messages-area .patient-resp {
  font-size: 14px;
  color: #5e5d5d;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: proxima-nova, "proxima nova", "helvetica neue", helvetica, arial, sans-serif;
  font-weight: 400;
}

.messages-area .patient-resp .resp-text {
  color: green;
  font-weight: 600;
}

.messages-area .patient-resp .date-resp {
  line-height: 20px;
  margin-top: 5px;
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #bcbcbc;
}

.messages-area .not-read {
  background-color: #f9f9f9;
}

.messages-area table tbody tr td a {
  color: #246fdc;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
}

.messages-area .footer-message {
  text-align: right;
  margin-right: 40px;
}

.messages-area .pagination {
  padding-right: 21px;
}

.messages-area .btn-messages-right {
  outline: 1px inset rgba(0, 0, 0, 0.1);
  outline-offset: -1px;
  color: #666666;
  background-color: #ffffff;
  height: 30px;
  margin-top: 10px;
  height: 35px;
  font-weight: 400;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -10px;
}

.messages-area .message-main-title {
  background: #f5f6f7;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -11px;
  border-bottom: solid 1px #e2e0e0;
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 10px;
}

.messages-count-button {
  background-color: #E9573F;
  color: white;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 1px;
  font-weight: 600;
}
