.payment-fee-card:hover .payment-fee-card__image-container {
  background: linear-gradient(180deg, rgba(66, 147, 224, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 12px 12px 0px 0px;
}

.payment-fee-card--inactive:hover .payment-fee-card__image-container {
  background: linear-gradient(180deg, rgba(110, 129, 147, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 12px 12px 0px 0px;
}

.payment-fee-installment__remove-button {
  display: none;
}

.payment-fee-installment:nth-last-child(2) .payment-fee-installment__remove-button, .payment-fee-installment:nth-last-child(2) .payment-fee-installment__fee:focus + .payment-fee-installment__remove-button,
.payment-fee-installment__remove-button:hover, .payment-fee-installment__remove-button:focus {
  display: block;
}

.payment-fee-installment__copy-fee-to-all {
  display: none;
}

.payment-fee-installment__copy-fee-to-all--visible, .payment-fee-installment__copy-fee-to-all:hover {
  display: block;
}

.payment-form__installments-help-text {
  display: none;
}

[data-credit-receipt-type=installments] .payment-form__installments-help-text {
  display: block;
}
